<template>
  <div class="row">
    <div class="col-10">
      <q-table title="Список документов" :rows="table_rows" :columns="table_columns" row-key="name" flat
        separator="cell" :pagination="tablePagination" @row-click="onRowClick" v-model="table_rows">

      </q-table>
    </div>
    <div class="col-2 q-pa-md" style="max-width: 320px;">
      <q-select v-model="document_direction" :options="document_direction_options" label="Направление документов"
        @update:model-value="(newValue) => { onFilterChange(newValue, document_type, is_active) }" />
      <q-select v-model="document_type" :options="document_type_options" label="Тип документов"
        @update:model-value="(newValue) => { onFilterChange(document_direction, newValue, is_active) }" />
      <q-select v-model="is_active" :options="document_active_options" label="Состояние документов"
        @update:model-value="(newValue) => { onFilterChange(document_direction, document_type, newValue) }" />

      <q-date v-model="period_range.from" flat minimal
        @update:model-value="(newValue) => { onPeriodChange({from: newValue, to: period_range.to} ) }" />
      <q-date v-model="period_range.to" flat minimal
        @update:model-value="(newValue) => { onPeriodChange({ from: period_range.from, to: newValue }) }" />
      <div v-if="period_range == null">
        Период не установлен
      </div>
      <div v-else>
        Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
      </div>
    </div>
  </div>
  <q-page-sticky position="bottom-right">
    <q-fab icon="add" direction="left" color="primary">
      <q-fab-action @click="$router.push({ name: 'document_create_upd', params: {} })" color="primary" label="УПД" />
      <q-fab-action @click="$router.push({ name: 'document_create_factura_avance', params: {} })" color="primary"
        label="Счет-фактура" />
    </q-fab>
  </q-page-sticky>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const document_direction_options = [
  { label: 'Любые', value: 'all' },
  { label: 'Входящие', value: 'in'},
  { label: 'Исходящие', value: 'out' },
  { label: 'Внутренние', value: 'internal' }
]

const document_type_options = [
  { label: 'Все', value: 'upd,avance' },
  { label: 'УПД', value: 'upd' },
  { label: 'Счет-фактура', value: 'avance' },
  { label: 'Счета на оплату', value: 'bill_to_pay' },
  { label: 'Акт сверки', value: 'act_sver' }
]

const document_active_options = [
  { label: 'Активные', value: true },
  { label: 'Удаленные', value: false }
]

const table_columns = [
  { name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true },
  { name: 'name', align: 'left', label: 'Название', field: 'name' },
  { name: 'user', align: 'left', label: 'Пользователь', field: 'user', sortable: true },
  { name: 'direction', align: 'left', label: 'Направление', field: 'direction_display', sortable: true },
  { name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true },
]

export default {
  name: "documents_list",
  components: {
  },
  data() {
    this.emitter.on('didReceiveDocumentsList', (data) => {
      console.log(data)
      this.table_rows = data.data;
      this.isUpdatingData = false;
    })
    this.emitter.on('didReceiveDocumentsTemplates', (data) => {
      console.log('ororororr', data)
      this.templates_list_options = [{ label: 'Все', value: 'all' }].concat(data.data.map((element) => { return { label: element.name, value: element.id } }));
    })
    const period_start = moment().quarter(moment().quarter()).startOf('quarter').endOf('day');
    const period_end = moment().endOf('day');
    const period_start_unix = period_start.unix();
    const period_end_unix = period_end.unix();
    var range = this.$store.state.filter_period;
    if (this.connection.isAuthenticated) {
      this.connection.getDocumentsList(
        '1',
        this.$store.state.current_org_id,
        moment(range.from).startOf('day').unix(),
        moment(range.to).endOf('day').unix(),
        document_direction_options[0].value,
        document_type_options[0].value,
        true
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getDocumentsList(
          '1',
          this.$store.state.current_org_id,
          moment(range.from).startOf('day').unix(),
          moment(range.to).endOf('day').unix(),
          document_direction_options[0].value,
          document_type_options[0].value,
          true
        );
      })
    }
    return {
      isUpdatingData: ref(true),
      table_rows: ref([]),
      period_start_unix: ref(period_start_unix),
      period_end_unix: ref(period_end_unix),
      incoming: ref('all'),
      template_id: ref({ label: 'Все', value: -1 }),
      templates_list_options: ref([]),
      document_direction: ref(document_direction_options[0]),
      document_direction_options: document_direction_options,
      document_type: ref(document_type_options[0]),
      document_type_options: document_type_options,
      document_active_options: ref(document_active_options),
      is_active: ref(document_active_options[0]),
      period_range: ref(range),
    }
  },
  setup() {
    moment.locale('ru');
    const period_start_proxy = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
    const period_end_proxy = ref(moment().endOf('day').format("YYYY/MM/DD"));
    const period_start = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
    const period_end = ref(moment().endOf('day').format("YYYY/MM/DD"));
    return {
      create_new_bill: ref(false),
      create_new_transfer: ref(false),
      table_columns,

      tablePagination: {
        rowsPerPage: 0
      },
      period_start_proxy,
      period_end_proxy,
      period_start,
      period_end
    }
  },
  methods: {
    updateFilterStartProxy() {
      this.period_start_proxy = this.period_start
    },
    updateFilterEndProxy() {
      this.period_start_proxy = this.period_start
    },
    filterSaveStartProxy() {
      this.period_start = this.period_start_proxy;
      this.period_start_unix = moment(this.period_start_proxy).endOf('day').unix();
      this.filterSave();
    },
    filterSaveEndProxy() {
      this.period_end = this.period_end_proxy;
      this.period_end_unix = moment(this.period_end_proxy).endOf('day').unix();
      this.filterSave();
    },
    onRowClick(event, row) {
      if (row.document_type === 'upd') {
        this.$router.push({ name: 'document_info_upd', params: { id: row.id, history_id: 'last' } })
      } else if (row.document_type === 'avance') {
        this.$router.push({ name: 'document_info_factura_avance', params: { id: row.id, history_id: 'last' } })
      } else if (row.document_type === 'bill_to_pay') {
        this.$router.push({ name: 'document_info_bill_to_pay', params: { id: row.id, history_id: 'last' } })
      } else if (row.document_type === 'act_sver') {
        this.$router.push({ name: 'document_info_act_sver', params: { id: row.id, history_id: 'last' } })
      } 

    },
    onSubRowClick(event, row) {
      console.log(row);
    },
    onPeriodChange(model_value) {
      console.log(model_value);
      if (model_value == null) {
        return;
      }
      this.$store.commit('updateFilterPeriod', model_value);
      this.isUpdatingData = true;
      if (this.connection.isAuthenticated) {
        this.connection.getDocumentsList(
          '1',
          this.$store.state.current_org_id,
          moment(model_value.from).endOf('day').unix(),
          moment(model_value.to).endOf('day').unix(),
          this.document_direction.value,
          this.document_type.value,
          this.is_active.value
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getDocumentsList(
            '1',
            this.$store.state.current_org_id,
            moment(model_value.from).endOf('day').unix(),
            moment(model_value.to).endOf('day').unix(),
            this.document_direction.value,
            this.document_type.value,
            this.is_active.value
          );
        })
      }
    },
    onFilterChange(direction, doctype, is_active) {
      if (this.connection.isAuthenticated) {
        this.connection.getDocumentsList(
          '1',
          this.$store.state.current_org_id,
          moment(this.period_range.from).endOf('day').unix(),
          moment(this.period_range.to).endOf('day').unix(),
          direction.value,
          doctype.value,
          is_active.value
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getDocumentsList(
            '1',
            this.$store.state.current_org_id,
            moment(this.period_range.from).endOf('day').unix(),
            moment(this.period_range.to).endOf('day').unix(),
            direction.value,
            doctype.value,
            is_active.value
          );
        })
      }
    },
    prettyDatePrint(date) {
      return moment(date).format('DD MMMM YYYY');
    }
  },
}
</script>

<style scoped></style>