<template>
	<q-page class="q-pa-md">
		<h5 class="">Первоначальные данные бухгалтерии</h5>

		<q-btn flat dense icon="add" label="Добавить" @click="addRowDialog = true" />
		<q-table flat wrap-cells="" borderless :rows="table_rows" :columns="table_columns" row-key="id"
			:pagination="initialPagination" :loading="loading">
			<template v-slot:body-cell-status="props">
				<q-td :props="props">
					<q-chip :color="getStatusColor(props.value)" text-color="white">
						{{ getStatusVerbose(props.value) }}
					</q-chip>
				</q-td>
			</template>
			<template v-slot:body-cell-summ="props">
				<q-td :props="props">
					<q-chip color="info" text-color="white">
						{{ props.value }} руб.
					</q-chip>
				</q-td>
			</template>
			<template v-slot:body-cell-bill_to="props">
				<q-td :props="props">
					{{ (props.value || {full_name: ''} ).full_name }}
				</q-td>
			</template>

			<template v-slot:body-cell-bill="props">
				<q-td :props="props">
					{{ (props.value || { full_name: '' }).full_name }}
				</q-td>
			</template>


			<template v-slot:body-cell-direction="props">
				<q-td :props="props">
					<q-chip color="info" text-color="white">
						{{ props.value.label }}
					</q-chip>
				</q-td>
			</template>

			<template v-slot:body-cell-bill_from="props">
				<q-td :props="props">
					{{ (props.value || { full_name: '' }).full_name }}
				</q-td>
			</template>

			<template v-slot:body-cell-filter_field="props">
				<q-td :props="props">
					{{ props.value.label }}
				</q-td>
			</template>
			<template v-slot:body-cell-actions="props">
				<q-td :props="props">
					<q-btn flat round color="primary" icon="info" @click="showInfo(props.row)">
						<q-tooltip>Информация об остатке</q-tooltip>
					</q-btn>
				</q-td>
			</template>
		</q-table>
		<q-dialog v-model="addRowDialog" persistent>
			<q-card style="min-width: 350px">
				<q-card-section>
					<div v-if="edit_mode" class="text-h6">Изменить остаток</div>
					<div v-else class="text-h6">Добавить остаток</div>
				</q-card-section>

				<q-card-section>
					<q-list>
						<q-item>
							<q-item-section>
								<q-item-label>
									<q-input dense flat label="Дата" v-model="new_transfer_date">
										<template v-slot:append>
											<q-icon name="event" class="cursor-pointer">
												<q-popup-proxy cover transition-show="scale" transition-hide="scale">
													<q-date mask="DD.MM.YYYY" v-model="new_transfer_date"
														@update:model-value="(newValue) => onDateChanged(newValue)">
														<div class="row items-center justify-end">
															<q-btn v-close-popup label="Закрыть" color="primary" flat />
														</div>
													</q-date>
												</q-popup-proxy>
											</q-icon>
										</template>
									</q-input>
								</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label>
									<q-input v-model="new_summ" type="number" label="Сальдо на начало периода"
										:rules="[val => !!val || 'Поле обязательно для заполнения']" />
								</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label>
									<q-select v-model="new_bill" :options="bills_list" label="Счет"
										:rules="[val => !!val || 'Поле обязательно для заполнения']" />
								</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label>
									<q-select v-model="new_transfer_direction" :options="direction_options"
										label="Тип остатка"
										:rules="[val => !!val || 'Поле обязательно для заполнения']" />
								</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label>
									<q-input v-model="new_comment" type="text" label="Коментарий" />
								</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label>
									<q-input v-model="new_field_seller_inn" label="ИНН продавца" />
								</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label>
									<q-input v-model="new_field_cust_inn" label="ИНН покупателя" />
								</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label>
									<q-input v-model="new_field_name_id" label="Товар" />
								</q-item-label>
							</q-item-section>
						</q-item>
					</q-list>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn v-show="edit_mode" flat label="Удалить" color="primary" @click="delete_transfer" />
					<q-btn v-if="edit_mode" flat label="Сохранить" color="primary" @click="add_transfer" />
					<q-btn v-else flat label="Добавить" color="primary" @click="add_transfer" />
					<q-btn flat label="Закрыть" color="primary" v-close-popup />
				</q-card-actions>
			</q-card>
		</q-dialog>
	</q-page>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const table_columns = [
	{ name: 'summ', align: 'left', label: 'Сальдо на начало', field: 'summ' },
	{ name: 'date', align: 'left', label: 'Дата', field: 'date' },
	{ name: 'bill', align: 'left', label: 'Счет', field: 'bill' },
	{ name: 'direction', align: 'left', label: 'Тип остатка', field: 'direction' },
	{ name: 'comment', align: 'left', label: 'Коментарий', field: 'comment' },
	{ name: 'seller_inn', align: 'left', label: 'Продавец', field: 'seller_inn' },
	{ name: 'cust_inn', align: 'left', label: 'Покупатель', field: 'cust_inn' },
	{ name: 'name_id', align: 'left', label: 'Товар', field: 'name_id' },
	{ name: 'status', align: 'left', label: 'Статус', field: 'status' },
	{ name: 'actions', label: 'Действия', field: 'actions' }
]

const filter_field_options = [
	{ label: 'Продавец', value: 'seller_inn' },
	{ label: 'Покупатель', value: 'cust_inn' },
	{ label: 'Товар', value: 'name_id' }
];


const direction_options = [
	{ label: 'Дебет', value: 'debet' },
	{ label: 'Кредит', value: 'credit' },
];

export default {
	name: "initial_transfers_master",
	data() {

		if (this.connection.isAuthenticated) {
			this.connection.gitBillsListFlatCompact(
				'1', 
				this.$store.state.current_org_id
			);
			this.connection.listInitialTransfers(
				'1',
				this.$store.state.current_org_id
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.gitBillsListFlatCompact(
					'1',
					this.$store.state.current_org_id
				);
				this.connection.listInitialTransfers(
					'1',
					this.$store.state.current_org_id
				);
			});
		}
		this.emitter.on('onListInitialTransfers', (data) => {
			this.table_rows = data.transfers.map((element) => {
				console.log(element.bill_to != null, element.bill_to, "A")
				return {
                    ...element,
                    bill: element.bill_to != null ? element.bill_to : element.bill_from,
					direction: element.bill_to != null ? this.direction_options[0] : this.direction_options[1],
                }
			});
		});
		this.emitter.on('onEditInitialTransfer', (data) => {
			console.log(data);
			this.connection.listInitialTransfers(
				'1',
				this.$store.state.current_org_id
			);
		});
		this.emitter.on('onDeleteTransfer', (data) => {
			console.log(data);
			this.connection.listInitialTransfers(
				'1',
				this.$store.state.current_org_id
			);
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element => {
				return {
					label: element.display_name,
					full_name: element.full_name,
					value: element.id
				}
			}));
		})
		this.emitter.on('onCreateInitialTransfersWildcard', (response) => {
			if (response.type == 'error') {
				this.$q.notify('Ошибка');
			} else {
				this.connection.listInitialTransfers(
					'1',
					this.$store.state.current_org_id
				);
			}
            this.$q.loading.hide();
			this.$q.notify('Остатки успешно внесены');
		});
		this.emitter.on('didCreateNewSubbill', (data) => {
			console.log(data);
			this.connection.gitBillsListFlatCompact(
				'1',
				this.$store.state.current_org_id
			);
		});
		return {
			filter_field_options: ref(filter_field_options),
			table_columns: ref(table_columns),
			table_rows: ref([]),
			new_id: ref(1),
			new_name: ref(null),
			new_summ: ref(null),
			new_bill: ref(null),
			new_bill_to: ref(null),
			new_bill_from: ref(null),
            new_comment: ref(null),
            new_field_seller_inn: ref(null),
			new_field_cust_inn: ref(null),
			new_field_name_id: ref(null),
			new_transfer_date: ref(null),
			new_transfer_date_unix: ref(null),
			new_transfer_direction: ref(direction_options[0]),
			direction_options: ref(direction_options),
			loading: ref(false),
			initialPagination: {
				sortBy: 'id',
				descending: false,
				page: 1,
				rowsPerPage: 100
			},
			addRowDialog: ref(false),
			bills_list: ref([]),
			edit_mode: ref(false)
		}
	},
	methods: {
		onDateChanged(newValue) {
			this.new_transfer_date_unix = moment(newValue, 'DD.MM.YYYY').unix() + 1800
		},
		getStatusColor(status) {
			switch (status.toLowerCase()) {
				case 'new': return 'grey'
				case 'saved': return 'positive'
				case 'failed': return 'negative'
				default: return 'grey'
			}
		},
		getStatusVerbose(status) {
			switch (status.toLowerCase()) {
				case 'new': return 'Не сохранено'
				case 'saved': return 'Сохранено'
				case 'failed': return 'Ошибка'
				default: return 'Неизвестно'
			}
		},
		add_transfer() {
			if (this.edit_mode) {
				const item = {
					id: this.new_id,
					name: this.new_name,
					summ: this.new_summ,
					bill_to: this.new_transfer_direction.value == 'debet' ? this.new_bill : null,
					bill_from: this.new_transfer_direction.value == 'credit' ? this.new_bill : null,
					comment: this.new_comment,
					seller_inn: this.new_field_seller_inn,
					cust_inn: this.new_field_cust_inn,
					name_id: this.new_field_name_id,
					date: this.new_transfer_date,
					date_unix: this.new_transfer_date_unix,
					status: 'new'  // 'new', 'saved', 'failed'
				}
				this.connection.editInitialTransfer(
					'1',
					this.$store.state.current_org_id,
					item.id,
					'Внесение остатков',
					item.summ,
					item.bill_to !== null ? item.bill_to.value : null,
					item.bill_from !== null ? item.bill_from.value : null,
					item.comment,
					item.seller_inn,
					item.cust_inn,
					item.name_id,
					item.date_unix
				)
			} else {
				const item = {
					id: this.new_id,
					name: this.new_name,
					summ: this.new_summ,
					bill_to: this.new_transfer_direction.value == 'debet' ? this.new_bill : null,
					bill_from: this.new_transfer_direction.value == 'credit' ? this.new_bill : null,
					comment: this.new_comment,
					seller_inn: this.new_field_seller_inn,
					cust_inn: this.new_field_cust_inn,
					name_id: this.new_field_name_id,
					date: this.new_transfer_date,
					date_unix: this.new_transfer_date_unix,
					status: 'new'  // 'new', 'saved', 'failed'
				}
				this.table_rows.push(item);
				this.connection.createInitialTransfersWildcard(
					'1',
					this.$store.state.current_org_id,
					'Внесение остатков',
					item.summ,
					item.bill_to !== null ? item.bill_to.value : null,
					item.bill_from !== null ? item.bill_from.value : null,
					item.comment,
					item.seller_inn,
					item.cust_inn,
					item.name_id,
					item.date_unix
				)
			}
			this.new_id = 0;
			this.new_name = null;
			this.new_summ = null;
			this.new_bill_to = null;
			this.new_bill_from = null;
			this.new_comment = null;
			this.new_field_seller_inn = null;
			this.new_field_cust_inn = null;
			this.new_field_name_id = null;
			this.new_bill = null;
			this.new_transfer_date = null;
			this.new_transfer_date_unix = null;
			this.addRowDialog = false;
			this.edit_mode = false;
		},
		showInfo(item) {
			this.new_id = item.id;
			this.new_name = item.name;
			this.new_summ = item.summ;
			this.new_bill_to = item.bill_to;
			this.new_bill_from = item.bill_from;
			this.new_comment = item.comment;
			this.new_field_seller_inn = item.seller_inn;
			this.new_field_cust_inn = item.cust_inn;
			this.new_field_name_id = item.name_id;
			if (item.bill_to !== undefined) {
				this.new_bill = item.bill_to;
				this.new_transfer_direction = this.direction_options[0]
			} else {
				this.new_bill.item.bill_from;
				this.new_transfer_direction = this.direction_options[1]
			}
			this.new_transfer_date = item.date;
			this.new_transfer_date_unix = item.date_unix;
			this.addRowDialog = true;
			this.edit_mode = true;
			console.log("as", this.new_bill)
		},
		delete_transfer() {
			this.connection.deleteTransfer(
                '1',
                this.$store.state.current_org_id,
                this.new_id
			)
			this.new_id = 0;
			this.new_name = null;
			this.new_summ = null;
			this.new_bill_to = null;
			this.new_bill_from = null;
			this.new_comment = null;
			this.new_field_seller_inn = null;
			this.new_field_cust_inn = null;
			this.new_field_name_id = null;
			this.new_bill = null;
			this.new_transfer_date = null;
			this.new_transfer_date_unix = null;
			this.addRowDialog = false;
			this.edit_mode = false;
		}
	}
}
</script>

<style scoped></style>