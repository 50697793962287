<template>
  <div class="row">
    <div class="col-10">
      <q-table title="Платежные поручения" :rows="table_rows" :columns="table_columns" row-key="in_org_number" flat
        wrap-cells="true" :pagination="tablePagination" @row-click="onRowClick" :selected-rows-label="getSelectedString"
        selection="multiple" v-model:selected="selected">
        <template v-slot:bottom>
          <q-btn v-show="selected.length > 0" icon="download" flat dense label="Выгрузить" @click="onDownloadSelectedPaymentsTxt" />
        </template>
      </q-table>
    </div>

    <div class="col-2 q-pa-md" style="max-width: 320px;">
      <q-select v-model="document_direction" :options="document_direction_options" label="Направление документов"
        @update:model-value="(newValue) => { onFilterChange(newValue, is_active) }" />


      <q-select v-model="payment_state" :options="payment_state_options" label="Состояние платежных поручений"
        @update:model-value="(newValue) => { onFilterChange(document_direction, is_active) }" />
      <q-select v-model="is_active" :options="document_active_options" label="Состояние документов"
        @update:model-value="(newValue) => { onFilterChange(document_direction, newValue) }" />
      <q-date v-model="period_range.from" flat minimal
        @update:model-value="(newValue) => { onPeriodChange({from: newValue, to: period_range.to}) }" />
      <q-date v-model="period_range.to" flat minimal
        @update:model-value="(newValue) => { onPeriodChange({from: period_range.from, to: newValue}) }" />
      <div v-if="period_range == null">
        Период не установлен
      </div>
      <div v-else>
        Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
      </div>
    </div>
  </div>
  <q-page-sticky position="bottom-right" >
    <q-fab icon="add" direction="left" color="primary">
      <q-fab-action @click="$router.push({ name: 'document_create_payment', params: {} })" color="primary"
        label="Поручение вручную" />
      <q-fab-action @click="payment_from_file = true" color="primary" label="Поручение из файла" />
    </q-fab>
  </q-page-sticky>
  <q-dialog v-model="payment_from_file" persistent>
    <q-card style="width: 380px; min-height: 200px;">
      <q-card-section class="row items-center">
        <span class="q-ml-sm">Загрузить файл клиент-банка</span>
      </q-card-section>
      <q-form @submit="onSubmitPaymentFile">


        <q-file class="q-ma-md" bottom-slots v-model="file" label="Выберите файл" name="file" counter>
          <template v-slot:prepend>
            <q-icon name="cloud_upload" @click.stop.prevent />
          </template>
          <template v-slot:append>
            <q-icon name="close" @click.stop.prevent="file = null" class="cursor-pointer" />
          </template>
        </q-file>

        <q-card-actions align="right">
          <q-btn flat label="Загрузить" color="primary" type="submit" v-close-popup />
          <q-btn flat label="Отмена" color="negative" v-close-popup />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import {ref} from "vue";
import moment from "moment";
import axios from "axios";

const table_columns = [
  { name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true},
  { name: 'name', align: 'left', label: 'Название', field: 'name'},
  { name: 'summ', align: 'left', label: 'Сумма', field: 'summ', sortable: true },
  { name: 'maked', align: 'left', label: 'Исполнено', field: 'maked', sortable: true },
  { name: 'direction', align: 'left', label: 'Направление', field: 'direction_display', sortable: true},
  { name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true},
  { name: 'user', align: 'left', label: 'Пользователь', field: 'user', sortable: true},
];

const document_direction_options = [
  { label: 'Любые', value: 'all' },
  { label: 'Входящие', value: 'in' },
  { label: 'Исходящие', value: 'out' }
];

const document_active_options = [
  { label: 'Активные', value: true },
  { label: 'Удаленные', value: false }
];

const payment_state_options = [
  { label: 'Исполненные', value: true },
  { label: 'Не исполненные', value: false }
];

export default {
  name: "payments_list",
  components: {
    
  },
  data() {
    this.emitter.on('onCreateTxtFromPayement', (response) => {

      fetch(response.url)
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network Problem");
          }
          return res.blob();
        })
        .then((file) => {
          var anchor = document.createElement("a")
          anchor.href = URL.createObjectURL(file);
          anchor.download = "erp_to_kl.txt"
          anchor.setAttribute('target', '_blank')
          anchor.click()
          URL.revokeObjectURL(anchor.href)
          anchor.remove()
        })
        .catch(() => {

        });
    });
    this.emitter.on('didReceiveDocumentsList', (data) => {
      console.log(data)
      this.table_rows = data.data;
      this.isUpdatingData = false;
    })
    const period_start = moment().quarter(moment().quarter()).startOf('quarter').endOf('day');
    const period_end = moment().endOf('day');
    const period_start_unix = period_start.unix();
    const period_end_unix = period_end.unix();
    var range = this.$store.state.filter_period;
    if (this.connection.isAuthenticated) {
      this.connection.getDocumentsList(
        '1',
        this.$store.state.current_org_id,
        moment(range.from).startOf('day').unix(),
        moment(range.to).endOf('day').unix(),
        'all',
        'payment',
        true
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getDocumentsList(
          '1',
          this.$store.state.current_org_id,
          moment(range.from).startOf('day').unix(),
          moment(range.to).endOf('day').unix(),
          'all',
          'payment',
          true
        );
      })
    }
    return {
      file: ref(null),
      payment_from_file: ref(false),
      isUpdatingData: ref(true),
      table_rows: ref([]),
      period_start_unix: ref(period_start_unix),
      period_end_unix: ref(period_end_unix),
      incoming: ref('all'),
      period_range: ref(range),
      document_direction: ref(document_direction_options[0]),
      document_direction_options: document_direction_options,
      document_active_options: ref(document_active_options),
      is_active: ref(document_active_options[0]),
      payment_state_options: ref(payment_state_options),
      payment_state: ref(payment_state_options[0]),
      selected: ref([])
    }
  },
  setup() {
    moment.locale('ru');
    const period_start_proxy = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
    const period_end_proxy = ref(moment().endOf('day').format("YYYY/MM/DD"));
    const period_start = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
    const period_end = ref(moment().endOf('day').format("YYYY/MM/DD"));
    console.log(period_start, period_end);
    return {
      create_new_bill: ref(false),
      create_new_transfer: ref(false),
      table_columns,

      tablePagination: {
        rowsPerPage: 0
      },
      period_start_proxy,
      period_end_proxy,
      period_start,
      period_end,
    }
  },
  methods: {
    onRowClick(event, row) {
      this.$router.push({name: 'document_info_payment', params: {id: row.id, history_id: 'last'}});
    },
    onSubRowClick(event, row) {
      console.log(row);
    },
    onPeriodChange(model_value) {
      console.log(model_value);
      if (model_value == null) {
        return;
      }
      this.$store.commit('updateFilterPeriod', model_value);
      this.isUpdatingData = true;
      if (this.connection.isAuthenticated) {
        this.connection.getDocumentsList(
          '1',
          this.$store.state.current_org_id,
          moment(model_value.from).startOf('day').unix(),
          moment(model_value.to).endOf('day').unix(),
          this.document_direction.value,
          'payment',
          this.is_active.value
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getDocumentsList(
            '1',
            this.$store.state.current_org_id,
            moment(model_value.from).startOf('day').unix(),
            moment(model_value.to).endOf('day').unix(),
            this.document_direction.value,
            'payment',
            this.is_active.value
          );
        })
      }
    },
    prettyDatePrint(date) {
      return moment(date).format('DD MMMM YYYY');
    }, 
    getSelectedString() {
      console.log(this.selected)
      return `Выбрано документов: ${this.selected.length}`
    },
    onFilterChange(direction, is_active) {
      if (this.connection.isAuthenticated) {
        this.connection.getDocumentsList(
          '1',
          this.$store.state.current_org_id,
          moment(this.period_range.from).startOf('day').unix(),
          moment(this.period_range.to).endOf('day').unix(),
          direction.value,
          'payment',
          is_active.value
        );
      } else {
        this.emitter.on('connectionReady', () => {
          this.connection.getDocumentsList(
            '1',
            this.$store.state.current_org_id,
            moment(this.period_range.from).startOf('day').unix(),
            moment(this.period_range.to).endOf('day').unix(),
            direction.value,
            'payment',
            is_active.value
          );
        })
      }
    },
    onDownloadSelectedPaymentsTxt() {
      var document_ids = this.selected.map((x) => x.id)
      this.connection.createTxtFromPayement(
        '1',
        this.$store.state.current_org_id,
        document_ids
      )
    },
    onSubmitPaymentFile() {
      var formData = new FormData();
      formData.append('file', this.file);
      formData.append('user', this.$store.state.user_info_across_org.user.id);
      formData.append('org_id', this.$store.state.current_org_id);
      axios({
        method: "post",
        url: "https://api.siderp.ru/upload/payment/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          if (response.error != undefined) {
            this.$q.notify({
              progress: true,
              message: "Ошибка при обработке файла",
              icon: 'error',
              color: 'white',
              textColor: 'negative'
            });
          } else {
            this.$q.notify({
              progress: true,
              message: "Документ создан",
              icon: 'checkmark',
              color: 'white',
              textColor: 'primary'
            });
            this.connection.getDocumentsList(
              '1',
              this.$store.state.current_org_id,
              moment(this.period_range.from).endOf('day').unix(),
              moment(this.period_range.to).endOf('day').unix(),
              this.direction.value,
              'payment',
              this.is_active.value
            );
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          this.$q.notify({
            progress: true,
            message: "Ошибка при загрузке файла",
            icon: 'error',
            color: 'white',
            textColor: 'negative'
          });
        });
    },
  }
}
</script>

<style scoped>

</style>