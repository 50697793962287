<template>
	<div class="container">
		<h6 class="header">Отчет о целевом использовании средств <q-input class="inline" dense flat
				v-model="document_date">
				<template v-slot:append>
					<q-icon name="event" class="cursor-pointer">
						<q-popup-proxy cover transition-show="scale" transition-hide="scale">
							<q-date mask="DD.MM.YYYY" v-model="document_date"
								@update:model-value="(newValue) => onDateChanged(newValue)">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Закрыть" color="primary" flat />
								</div>
							</q-date>
						</q-popup-proxy>
					</q-icon>
				</template>
			</q-input></h6>
		<div class="row" style="width: 180mm;">
			<div class="col-8">
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организация" class="inline" style="width: 100%;" dense flat
						v-model="organisation_name" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Идентификационный номер налогоплательщика" class="inline" style="width: 100%;" dense
						flat v-model="organisation_inn" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Вид экономической деятельности" class="inline" style="width: 100%;" dense flat
						v-model="organisation_econ" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организационно-правовая форма/форма собственности" class="inline"
						style="width: 100%;" dense flat v-model="organisation_form" />
				</p>
				<p style="width: 100%; padding-right: 10mm">Единица измерения: <q-select v-model="currency_measure"
						:options="currency_measure_options" class="inline" dense flat />
				</p>
			</div>
			<div class="col-4">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
				</table>
			</div>
		</div>

		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 25mm;">Пояснения</th>
						<th style="width: 80mm;">Наименование показателя</th>
						<th style="width: 37.5mm;">На {{ formatDate(document_date) }}г.</th>
						<th style="width: 37.5mm;">На 31 декабря 2023 г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<q-input class="inline" v-model="mon_ost.desc" dense flat />
						</td>
						<td>Остаток средств на начало отчетного года
						</td>
						<td><q-input class="inline" v-model="mon_ost.current" dense flat /></td>
						<td><q-input class="inline" v-model="mon_ost.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="mon_inc_invite.desc" dense flat />
						</td>
						<td>
							<div style="width: 100%; text-align: center;">
								Поступило средств
							</div>
							<div style="width: 100%; text-align: left;">
								Вступительные взносы
							</div>
						</td>
						<td><q-input class="inline" v-model="mon_inc_invite.current" dense flat /></td>
						<td><q-input class="inline" v-model="mon_inc_invite.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="chlen_income.desc" dense flat />
						</td>
						<td>Членские взносы
						</td>
						<td><q-input class="inline" v-model="chlen_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="chlen_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="cel_income.desc" dense flat />
						</td>
						<td>Целевые взносы</td>
						<td><q-input class="inline" v-model="cel_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="cel_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="vol_mat_income.desc" dense flat />
						</td>
						<td>Добровольные имущественные взносы и пожертвования
						</td>
						<td><q-input class="inline" v-model="vol_mat_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="vol_mat_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="income_actions.desc" dense flat />
						</td>
						<td>Прибыль от приносящей доход деятельности
						</td>
						<td><q-input class="inline" v-model="income_actions.current" dense flat /></td>
						<td><q-input class="inline" v-model="income_actions.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="another_income.desc" dense flat />
						</td>
						<td>Прочие
						</td>
						<td><q-input class="inline" v-model="another_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="another_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="total_income.desc" dense flat />
						</td>
						<td>Всего поступило средств
						</td>
						<td><q-input class="inline" v-model="total_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="total_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="outcome_cel.desc" dense flat />
						</td>
						<td>Использовано средств <br />Расходы на целевые мероприятия
						</td>
						<td><q-input class="inline" v-model="outcome_cel.current" dense flat /></td>
						<td><q-input class="inline" v-model="outcome_cel.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="soc_help.desc" dense flat />
						</td>
						<td>в том числе:<br />социальная и благотворительная помощь</td>
						<td><q-input class="inline" v-model="soc_help.current" dense flat /></td>
						<td><q-input class="inline" v-model="soc_help.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="conf_outcome.desc" dense flat />
						</td>
						<td>проведение конференций, совещаний, семинаров и т.п.</td>
						<td><q-input class="inline" v-model="conf_outcome.current" dense flat /></td>
						<td><q-input class="inline" v-model="conf_outcome.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="another_conf.desc" dense flat />
						</td>
						<td>иные мероприятия</td>
						<td><q-input class="inline" v-model="another_conf.current" dense flat /></td>
						<td><q-input class="inline" v-model="another_conf.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="managers_sal.desc" dense flat />
						</td>
						<td>Расходы на содержание аппарата управления</td>
						<td><q-input class="inline" v-model="managers_sal.current" dense flat /></td>
						<td><q-input class="inline" v-model="managers_sal.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="salary.desc" dense flat />
						</td>
						<td>в том числе: <br />расходы, связанные с оплатой труда (включая начисления)</td>
						<td><q-input class="inline" v-model="salary.current" dense flat /></td>
						<td><q-input class="inline" v-model="salary.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="non_salary.desc" dense flat />
						</td>
						<td>выплаты, не связанные с оплатой труда</td>
						<td><q-input class="inline" v-model="non_salary.current" dense flat /></td>
						<td><q-input class="inline" v-model="non_salary.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="com_drive.desc" dense flat />
						</td>
						<td>расходы на служебные командировки и деловые поездки</td>
						<td><q-input class="inline" v-model="com_drive.current" dense flat /></td>
						<td><q-input class="inline" v-model="com_drive.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="household.desc" dense flat />
						</td>
						<td>содержание помещений, зданий, автомобильного транспорта и иного имущества (кроме ремонта)
						</td>
						<td><q-input class="inline" v-model="household.current" dense flat /></td>
						<td><q-input class="inline" v-model="household.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="repair.desc" dense flat />
						</td>
						<td>ремонт основных средств и иного имущества</td>
						<td><q-input class="inline" v-model="repair.current" dense flat /></td>
						<td><q-input class="inline" v-model="repair.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="anouther_outcome.desc" dense flat />
						</td>
						<td>прочие</td>
						<td><q-input class="inline" v-model="anouther_outcome.current" dense flat /></td>
						<td><q-input class="inline" v-model="anouther_outcome.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="inventary.desc" dense flat />
						</td>
						<td>Приобретение основных средств, инвентаря и иного имущества</td>
						<td><q-input class="inline" v-model="inventary.current" dense flat /></td>
						<td><q-input class="inline" v-model="inventary.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="another.desc" dense flat />
						</td>
						<td>Прочие</td>
						<td><q-input class="inline" v-model="another.current" dense flat /></td>
						<td><q-input class="inline" v-model="another.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="total_used.desc" dense flat />
						</td>
						<td>Всего использовано средств</td>
						<td><q-input class="inline" v-model="total_used.current" dense flat /></td>
						<td><q-input class="inline" v-model="total_used.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="remainder.desc" dense flat />
						</td>
						<td>Остаток средств на конец отчетного года</td>
						<td><q-input class="inline" v-model="remainder.current" dense flat /></td>
						<td><q-input class="inline" v-model="remainder.previous" dense flat /></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div style="margin-top: 10mm;">
		<q-btn color="primary" icon="save" label="Сохранить" @click="onSave" />
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { nanoid } from "nanoid";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];

export default {
	name: "edit_document_report_money_using",
	data() {
		this.emitter.on("onSaveDocument", (data) => {
			console.log(data);
			this.$q.loading.hide();
			if (data.error) {
				this.$q.dialog({
					title: 'Ошибка',
					message: 'Документ не был сохранен',
					cancel: false,
					persistent: true
				}).onOk(() => {
				}).onCancel(() => {
					console.log('cancel')
				}).onDismiss(() => {
					console.log('dismiss')
				})
			} else {
				this.$q.notify('Документ был изменен');
				this.$router.back();
			}
		})
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			const body = response.data.body;
			this.organisation_name = body.organisation_name;
			this.organisation_inn = body.organisation_inn;
			this.organisation_econ = body.organisation_econ;
			this.organisation_form = body.organisation_form;
			this.document_date_unix = body.document_date_unix;
			this.document_date = body.document_date;
			this.currency_measure = body.currency_measure;
			this.mon_ost = body.mon_ost;
			this.mon_inc_invite = body.mon_inc_invite;
			this.chlen_income = body.chlen_income;
			this.cel_income = body.cel_income;
			this.vol_mat_income = body.vol_mat_income;
			this.income_actions = body.income_actions;
			this.another_income = body.another_income;
			this.total_income = body.total_income;
			this.outcome_cel = body.outcome_cel;
			this.soc_help = body.soc_help;
			this.conf_outcome = body.conf_outcome;
			this.another_conf = body.another_conf;
			this.managers_sal = body.managers_sal;
			this.salary = body.salary;
			this.non_salary = body.non_salary;
			this.com_drive = body.com_drive;
			this.household = body.household;
			this.repair = body.repair;
			this.anouther_outcome = body.anouther_outcome;
			this.inventary = body.inventary;
			this.another = body.another;
			this.total_used = body.total_used;
			this.remainder = body.remainder;

			this.in_org_number = body.in_org_number;
			this.document_date = moment.unix(body.document_date_unix).format('DD.MM.YYYY');
			this.document_date_unix = body.document_date_unix;
		});
		this.emitter.on('didReceiveAgentInfoByInn', (data) => {
			console.log(data, 'agent')
			if (data.request_id === 'rec_inn') {
				this.rec = data.info.name.short_with_opf;
				this.rec_kpp = data.info.kpp;
			}
			if (data.request_id === 'plat_inn') {
				this.plat = data.info.name.short_with_opf;
				this.plat_kpp = data.info.kpp;
			}
		})
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				nanoid(),
				this.$store.state.current_org_id,
				this.$route.params.id
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					nanoid(),
					this.$store.state.current_org_id,
					this.$route.params.id
				);
			})
		}
		return {
			step: ref(1),
			document_type: ref('report'),
			in_org_number: ref(null),
			organisation_name: ref(null),
			organisation_inn: ref(null),
			organisation_econ: ref(null),
			organisation_form: ref(null),
			document_date_unix: ref(0),
			document_date: ref(null),
			currency_measure_options: ref(currency_measure_options),
			currency_measure: ref(currency_measure_options[0]),
			mon_ost: ref({ current: 0, previous: 0, desc: '' }),
			mon_inc_invite: ref({ current: 0, previous: 0, desc: '' }),
			chlen_income: ref({ current: 0, previous: 0, desc: '' }),
			cel_income: ref({ current: 0, previous: 0, desc: '' }),
			vol_mat_income: ref({ current: 0, previous: 0, desc: '' }),
			income_actions: ref({ current: 0, previous: 0, desc: '' }),
			another_income: ref({ current: 0, previous: 0, desc: '' }),
			total_income: ref({ current: 0, previous: 0, desc: '' }),
			outcome_cel: ref({ current: 0, previous: 0, desc: '' }),
			soc_help: ref({ current: 0, previous: 0, desc: '' }),
			conf_outcome: ref({ current: 0, previous: 0, desc: '' }),
			another_conf: ref({ current: 0, previous: 0, desc: '' }),
			managers_sal: ref({ current: 0, previous: 0, desc: '' }),
			salary: ref({ current: 0, previous: 0, desc: '' }),
			non_salary: ref({ current: 0, previous: 0, desc: '' }),
			com_drive: ref({ current: 0, previous: 0, desc: '' }),
			household: ref({ current: 0, previous: 0, desc: '' }),
			repair: ref({ current: 0, previous: 0, desc: '' }),
			anouther_outcome: ref({ current: 0, previous: 0, desc: '' }),
			inventary: ref({ current: 0, previous: 0, desc: '' }),
			another: ref({ current: 0, previous: 0, desc: '' }),
			total_used: ref({ current: 0, previous: 0, desc: '' }),
			remainder: ref({ current: 0, previous: 0, desc: '' }),
		}
	},
	methods: {
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		checkAgentBy(bik_val, field_id) {
			const bik = bik_val.replace(/\s/g, "");
			console.log(bik, field_id)
			if (bik.length < 9) {
				return;
			}
			this.connection.getAgentInfoByBik(
				field_id,
				bik
			);
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		checkAgentByInn(inn_val, field_id) {
			const inn = inn_val.replace(/\s/g, "");
			console.log(inn, field_id)
			if (inn.length < 9) {
				return;
			}
			this.connection.getAgentInfoByInn(
				field_id,
				inn
			);
		},
		onSave() {
			const body = {
				organisation_name: this.organisation_name,
				organisation_inn: this.organisation_inn,
				organisation_econ: this.organisation_econ,
				organisation_form: this.organisation_form,
				currency_measure: this.currency_measure,
				mon_ost: this.mon_ost,
				mon_inc_invite: this.mon_inc_invite,
				chlen_income: this.chlen_income,
				cel_income: this.cel_income,
				vol_mat_income: this.vol_mat_income,
				income_actions: this.income_actions,
				another_income: this.another_income,
				total_income: this.total_income,
				outcome_cel: this.outcome_cel,
				soc_help: this.soc_help,
				conf_outcome: this.conf_outcome,
				another_conf: this.another_conf,
				managers_sal: this.managers_sal,
				salary: this.salary,
				non_salary: this.non_salary,
				com_drive: this.com_drive,
				household: this.household,
				repair: this.repair,
				anouther_outcome: this.anouther_outcome,
				inventary: this.inventary,
				another: this.another,
				total_used: this.total_used,
				remainder: this.remainder,
				document_date: this.document_date_unix,
				in_org_number: this.in_org_number,
				document_type: 'report',
				report_type: 'money_move'
			};
			this.$q.loading.show();
			this.connection.createNewDocument(
				'3',
				this.$store.state.current_org_id,
				body
			);

		}

	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	/* width: 210mm;
	height: 297mm; */
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
}
</style>
