<template>
  <q-layout view="hHh LpR fFf">
    <q-drawer v-model="leftDrawerOpen" :width="expanded ? 240 : 72" :mini="!expanded" :breakpoint="500" bordered
      content-class="bg-white">
      <div class="erp-menu-container">
        <!-- Logo -->
        <div class="erp-menu-logo">
          <q-avatar size="40px" class="cursor-pointer">
            <img src="./../../assets/logo_transp.png" alt="System Logo">
          </q-avatar>
        </div>

        <!-- Menu Items -->
        <q-scroll-area class="erp-menu-scroll">
          <q-list padding>
            <q-item v-for="link in links" :key="link.title" v-ripple clickable @click="onMenuClick(link)"
              active-class="erp-menu-link-active" class="erp-menu-link">
              <q-item-section avatar>
                <q-icon :name="link.icon" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ link.title }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>

        <!-- Bottom Section -->
        <div class="erp-menu-bottom">
          <q-item clickable v-ripple @click="toggleExpanded" class="erp-menu-link">
            <q-item-section avatar>
              <q-icon :name="expanded ? 'chevron_left' : 'chevron_right'" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ expanded ? 'Свернуть' : 'Развернуть' }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple class="erp-menu-link" @click="$router.push({ name: 'profile', params: {} })">
            <q-item-section avatar>
              <q-avatar size="20px" color="teal">
                <!-- <img src="https://cdn.quasar.dev/img/avatar.png" alt="User Avatar">
                  -->
                {{ (display_name || "?").substring(0, 1) }}
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>Профиль</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-ripple @click="logout" class="erp-menu-link">
            <q-item-section avatar>
              <q-icon name="logout" color="negative"/>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-negative">Выйти</q-item-label>
            </q-item-section>
          </q-item>
        </div>
      </div>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";

const links = [
  
];

export default {
  name: "dashboard",
  data() {

    this.emitter.on('didReceiveUserInfo', (data) => {
      this.user_info = data;
      this.email = data.email;
      this.display_name = data.display_name;
      if (this.$store.state.current_org_id === -1) {
        if (data.organisations.length > 0) {
          this.$store.commit('updateCurrentOrgId', data.organisations[0].id);
        }
      }
    });
    this.emitter.on('didReceoveUserInfoAcrossOrg', (data) => {
      this.$store.commit('userInfoAcrossOrg', data);
      this.user_info = data.user;
      if (data.user !== undefined) {
        if (data.user.organisations.length > 0) {
          this.can_open_finances = data.user.role.permissions.open_finance;
          this.can_open_org_structure = data.user.role.permissions.edit_roles;
        }
      } else {
        this.can_open_finances = false;
        this.can_open_org_structure = false;
      }
      var index = this.user_info.organisations.findIndex((x) => x.id == this.$store.state.current_org_id);
      if (index == -1) {
        index = 0;
        const new_org_id = this.user_info.organisations[0].id;
        this.$store.commit('updateCurrentOrgId', new_org_id);
        this.connection.getOrganisationInfo('1', new_org_id);
        this.connection.getUserInfoAcrossOrg('1', new_org_id);
      }
      var org_name = this.user_info.organisations[index].name;
      this.links = [];
      if (this.can_open_org_structure) {
        this.links.push({ title: org_name, icon: 'people', value: 'org_main' });
      }
      if (this.can_open_finances) {
        this.links.push({ title: 'ОСВ', icon: 'assessment', value: 'bill_saldo' })
        this.links.push({ title: 'План счетов', icon: 'difference', value: 'bills_plan' })
        this.links.push({ title: 'Акт сверки', icon: 'rule_folder', value: 'act_check_master' })
        this.links.push({ title: 'Документы', icon: 'folder_copy', value: 'documents_list' })
        this.links.push({ title: 'Платежные поручения', icon: 'request_quote', value: 'payments_list' })
        this.links.push({ title: 'Отчеты', icon: 'receipt', value: 'reports_list' })
        this.links.push({ title: 'Настройки бухгалтерии', icon: 'settings', value: 'org_profile_buh_automatic' })
      }
    });
    if (this.connection.isAuthenticated) {
      this.connection.getOrganisationInfo('1', this.$store.state.current_org_id);
      this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getOrganisationInfo('1', this.$store.state.current_org_id);
        this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
      });
    }
    return {
      user_info: ref(this.$store.state.user_info),
      can_open_finances: ref(false),
      can_open_org_structure: ref(false),
      rightDrawerOpen: ref(false),
      leftDrawer: ref(false),
      miniState: ref(false),
      email: ref(''),
      display_name: ref(''),
      leftDrawerOpen: ref(true),
      expanded: ref(true),
      links: ref(links)
    }
  },
  methods: {
    routeToHome() {
      console.log(this.user_info)
      if (this.user_info.organisations.length > 0) {
        if (this.can_open_org_structure) {
          this.$router.push({ name: 'org_main', params: {} });
        } else if (this.can_open_finances) {
          this.$router.push({ name: 'bills_plan', params: {} });
        } else {
          this.$router.push({ name: 'profile', params: {} });
        }

      } else {
        this.$router.push({ name: 'profile', params: {} });
      }
    },
    didAuthenticate() {
      console.log("emitted")
    },
    updateSocket(token) {
      console.log(this.$socket, this.socket, token);
    },
    onMenuClick(item) {
      this.$router.push({ name: item.value, params: {} });
      // Here you would typically use vue-router to navigate
      // For example: router.push({ name: title.toLowerCase() })
    },
    logout() {
      localStorage.clear();
      this.$store.commit('clearStore');
      document.location.reload();
    },
    toggleExpanded () {
      this.expanded = !this.expanded
    },
    getCurrentOrgIdName() {
      if (!this.user_info) {
        return "отсутствует"
      }
      if (!this.user_info.organisations) {
        return "отсутствует"
      }
      if (this.user_info.organisations.length == 0) {
        return "отсутствует";
      }
      var index = this.user_info.organisations.findIndex((x) => x.id == this.$store.state.current_org_id);
      if (index == -1) {
        index = 0;
        const new_org_id = this.user_info.organisations[0].id;
        this.$store.commit('updateCurrentOrgId', new_org_id);
        this.connection.getOrganisationInfo('1', new_org_id);
        this.connection.getUserInfoAcrossOrg('1', new_org_id);
      }
      return this.user_info.organisations[index].name;
    }
  }
}
</script>

<style lang="scss">
.q-drawer {
  transition: width 0.3s;
  background-color: #ffffff;
  overflow-x: hidden;
}

.erp-menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.erp-menu-logo {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.erp-menu-scroll {
  flex-grow: 1;
  overflow-x: hidden;
}

.erp-menu-bottom {
  padding: 16px;
}

.erp-menu-link {
  border-radius: 8px;
  margin-bottom: 4px;
  transition: all 0.3s;

  .q-item__section--avatar {
    min-width: 56px;
  }

  .q-item__label {
    font-size: 14px;
  }

  .q-icon {
    font-size: 20px;
  }
}

.erp-menu-link-active {
  color: #1976D2;
  background: rgba(25, 118, 210, 0.1);

  .q-icon {
    color: #1976D2;
  }
}

.q-drawer--mini {
  .erp-menu-link {
    justify-content: center;
    padding: 8px;
  }

  .q-item__section--main,
  .q-item__label {
    display: none;
  }

  .q-item__section--avatar {
    min-width: auto;
  }
}

// Ensure proper alignment in expanded mode
.q-drawer:not(.q-drawer--mini) {
  .erp-menu-link {
    padding-left: 16px;
    justify-content: flex-start;
  }

  .q-item__section--avatar {
    min-width: 40px;
    padding-right: 16px;
  }
}

// Remove scrollbars while allowing scrolling
.q-scrollarea__thumb {
  display: none !important;
}

// Ensure consistent width for scroll area content
.q-scrollarea__content {
  width: 100% !important;
}
</style>
