<template>
  <q-page-container>
    <q-page>
      <q-stepper style="max-width: 800px; margin: 0 auto;" flat borderless v-model="step" header-nav ref="stepper"
        color="primary" animated>
        <q-step :name="1" title="Создание организации" icon="assignment" :done="step > 1">
          <q-card flat class="q-mt-md">
            <q-card-section>
              <div class="text-h6">Введите ИНН</div>
              <div class="text-subtitle2">Необходимо для поиска данных по организации в открытых источниках</div>
            </q-card-section>

            <q-card-section>
              <q-input mask="## ## ###### ##" v-model="inn" label="ИНН"
                :rules="[val => !!val || 'ИНН не может быть пустым']" />
              <q-toggle v-model="is_director" label="Я директор" />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn color="primary" label="Создать" @click="createOrganization" :loading="isLoading" />
            </q-card-actions>
          </q-card>
        </q-step>

        <q-step :name="2" title="Информация об организации" icon="assignment_turned_in" :done="step > 2">
          <q-card flat v-if="organization" class="q-mt-md">
            <q-card-section>
              <div class="text-h6">Информация об органищации</div>
              <div class="text-subtitle2">Проверьте информацию о созданной организации</div>
            </q-card-section>

            <q-card-section>
              <q-list>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>Название</q-item-label>
                    <q-item-label>{{ organization.name }}</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>ИНН</q-item-label>
                    <q-item-label>{{ organization.inn }}</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label overline>Адрес</q-item-label>
                    <q-item-label>{{ organization.address }}</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item v-show="organization.director">
                  <q-item-section>
                    <q-item-label overline>Директор</q-item-label>
                    <q-item-label>{{ organization.director.post || "" }} {{ organization.director.name || "Не указано"}}</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-show="organization.ip_name">
                  <q-item-section>
                    <q-item-label overline>Директор</q-item-label>
                    <q-item-label>{{ organization.ip_name.surname }} {{ organization.ip_name.name }} {{
                      organization.ip_name.patronymic }} </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-show="organization.opf">
                  <q-item-section>
                    <q-item-label overline>Тип организации</q-item-label>
                    <q-item-label>{{ organization.opf }} </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
            <q-card-actions align="right">
              <q-btn color="primary" label="Продолжить" @click="routeToOrg" />
            </q-card-actions>
          </q-card>
        </q-step>
      </q-stepper>
    </q-page>
  </q-page-container>
</template>

<script>
import {ref} from 'vue'

export default {
  name: "create_new_orgainsation",
  data() {
    this.emitter.on("organisationCreated", (response) => {
      this.isLoading = false;
      if (response.error == undefined) {
        this.$store.commit('updateCurrentOrgId', response.organisation_id);
        this.organization = {
          name: response.organisation_metadata.name,
          inn: this.inn,
          address: response.organisation_metadata.full_data.address.unrestricted_value,
          director: response.organisation_metadata.full_data.management,
          ip_name: response.organisation_metadata.full_data.fio,
          opf: response.organisation_metadata.full_data.opf.full
        }
        this.step = 2;
        this.$q.notify({
          color: 'positive',
          message: 'Организация успешно создана'
        });
        this.connection.getOrganisationInfo('1', this.$store.state.current_org_id);
        this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
      } else {
        this.$q.notify({
          color: 'negative',
          message: 'Ошибка при создании организации. Пожалуйста, повторите попытку позже.'
        })
      }
    })
    return {
      step: ref(1),
      inn: ref(''),
      organization: ref(null),
      isLoading: ref(false),
      stepper: ref(null),
      is_director: ref(true),
    }
  },
  methods: {
    createOrganization() {
      if (!this.inn) {
        this.$q.notify({
          color: 'negative',
          message: 'ИНН не может быть пустым'
        })
        return
      }
      this.isLoading = true
      this.connection.createOrganisation(this.inn.replaceAll(/\s/g, ''), this.is_director, '1');
    },
    routeToOrg() {
      this.$router.push({ name: 'org_main', params: {} })
    }

  }

}
</script>

<style scoped>

</style>