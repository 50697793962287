<template>
	<div class="">
		<q-btn @click="onPrintButtonClick" unelevated color="white" icon-right="download" text-color="primary"
			label="Печать" />
		<q-btn disable @click="onEditDocument" unelevated color="white" icon-right="edit" text-color="primary"
			label="Проводки и редактирование" />
		<q-btn disable v-if="is_active" @click="onDeleteDocument" unelevated color="white" icon-right="delete"
			text-color="negative" label="Удалить" />
		<q-btn disable v-else @click="onRestoreDocument" unelevated color="white" icon-right="history"
			text-color="primary" label="Восстановить" />
	</div>

	<div class="doc landscape upd" id="print_content">
		<div class="container">

			<div style="text-align: center; width: 100%; padding: 5mm;">
				<div style="font-size: 18pt; font-weight: bold;">
					Акт сверки
				</div>
				<div>
					взаимных расчетов за период: {{ formatDate(body.document_date || 0) }}
				</div>
				<div>
					между {{ (body.additional_data || { my_org_name: '' }).my_org_name }} и {{ (body.additional_data || { contr_org_name: '' }).contr_org_name }}
				</div>
			</div>
			<div>
				Мы, нижеподписавшиеся, Директор {{ (body.additional_data || { my_org_name: '' }).my_org_name }} {{ (body.additional_data || { my_fio_name: '' }).my_fio_name }}
				и Директор {{ (body.additional_data || { contr_org_name: '' }).contr_org_name }} {{ (body.additional_data || { contr_fio_name: '' }).contr_fio_name }} с другой стороны, составили настоящий акт
				сверки в том, что состояние взаимных расчетов по данным учета следующее:
			</div>
			<table>
				<q-tr>
					<q-th colspan="2">
						По данным {{ (body.additional_data || {my_org_name: '' }).my_org_name }}
					</q-th>
					<q-th colspan="2">
						Российский рубль
					</q-th>
					<q-th colspan="2">
						По данным {{ (body.additional_data || { contr_org_name: '' }).contr_org_name }}
					</q-th>
					<q-th colspan="2">
						Российский рубль
					</q-th>
				</q-tr>


				<q-tr>
					<q-th colspan="2">
						Сальдо начальное
					</q-th>
					<q-th colspan="1">
						{{ (body.total_before || { my_total_debet : ''}).my_total_debet }}
					</q-th>
					<q-th colspan="1">
						{{ (body.total_before || { my_total_credit : ''}).my_total_credit }}
					</q-th>
					<q-th colspan="2">
						Сальдо начальное
					</q-th>
					<q-th colspan="1">
						{{ (body.total_before || { contr_total_debet : ''}).contr_total_debet }}
					</q-th>
					<q-th colspan="1">
						{{ (body.total_before || { contr_total_credit : ''}).contr_total_credit }}
					</q-th>
				</q-tr>
				<q-tr>
					<q-th v-for="col in table_columns" v-bind:key="col.field">
						{{ col.label }}
					</q-th>
				</q-tr>
				<q-tr v-for="row in body.rows" v-bind:key="row.id">
					<q-td v-for="col in table_columns" v-bind:key="col.field">
						{{ row[col.name] }}
					</q-td>
				</q-tr>
				<q-tr>
					<q-th colspan="2">
						Сальдо конечное
					</q-th>
					<q-th colspan="1">
						{{ (body.total || { my_total_debet : ''}).my_total_debet }}
					</q-th>
					<q-th colspan="1">
						{{ (body.total || { my_total_credit : ''}).my_total_credit }}
					</q-th>
					<q-th colspan="2">
						Сальдо конечное
					</q-th>
					<q-th colspan="1">
						{{ (body.total || { contr_total_debet : ''}).contr_total_debet }}
					</q-th>
					<q-th colspan="1">
						{{ (body.total || { contr_total_credit : ''}).contr_total_credit }}
					</q-th>
				</q-tr>
			</table>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

var style = "<style>" +
	"@page {" +
	"/*size: 210mm 297mm; */" +
	"  size: 297mm 210mm;" +
	"  /* Chrome sets own margins, we change these printer settings */" +
	"  margin: 10mm 10mm 10mm 10mm;" +
	"}" +
	"" +
	".container {" +
	"  margin: 0;" +
	"  padding: 0;" +
	"  font-family: 'Times New Roman', Times, serif;" +
	"  font-size: 12px;" +
	"  background: white;" +
	"  color: black;" +
	"  height: 210mm;" +
	"  width: 297mm;" +
	"  box-sizing: border-box;" +
	"  display: block;" +
	"  margin: auto;" +
	"  position: relative;" +
	"}" +
	"" +
	"table {" +
	"  width: 267mm;" +
	"  border-collapse: collapse;" +
	"  table-layout: fixed;" +
	"  margin-top: 10px;" +
	"  font-size: 8pt;" +
	"}" +
	"" +
	"th," +
	"td {" +
	"  border: 0.6mm solid black;" +
	"  min-height: 5mm;" +
	"  text-align: center;" +
	"  vertical-align: top;" +
	"}" +
	"" +
	"tr {" +
	"  min-height: 5mm;" +
	"}" +
	"" +
	".aligned-cells {" +
	"  text-align: left;" +
	"  vertical-align: bottom;" +
	"}" +
	"</style>";

export default {
	name: "document_info_act_sver",
	components: {
	},
	data() {
		this.emitter.on('onDeleteDocument', (response) => {
			console.log(response);
			this.$q.notify({
				progress: true,
				message: "Документ удален",
				icon: 'checkmark',
				color: 'white',
				textColor: 'primary'
			});
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			);
		});

		this.emitter.on('onCreateTxtFromPayement', (response) => {

			fetch(response.url)
				.then((res) => {
					if (!res.ok) {
						throw new Error("Network Problem");
					}
					return res.blob();
				})
				.then((file) => {
					var anchor = document.createElement("a")
					anchor.href = URL.createObjectURL(file);
					anchor.download = "erp_to_kl.txt"
					anchor.setAttribute('target', '_blank')
					anchor.click()
					URL.revokeObjectURL(anchor.href)
					anchor.remove()
				})
				.catch(() => {

				});
		});
		this.emitter.on('onRestoreDocument', (response) => {
			console.log(response);
			this.$q.notify({
				progress: true,
				message: "Документ восстановлен",
				icon: 'checkmark',
				color: 'white',
				textColor: 'primary'
			});
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			);
		});
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			// const changes = response.data.changes;
			console.log(response.body);
			this.name = response.data.name;
			this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
			this.changes = response.data.changes;
			this.transfers = response.data.transfers;
			this.is_active = response.data.is_active;
			this.$q.loading.hide();
		});
		this.emitter.on('onCreateUpdTransferWildcard', (data) => {
			console.log(data)
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.$route.params.history_id
			);
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element) => {
				return { label: element.full_name, value: element.id }
			});
		});
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.$route.params.history_id
			);
			this.connection.gitBillsListFlatCompact(
				'1',
				this.$store.state.current_org_id,
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					'1',
					this.$store.state.current_org_id,
					this.$route.params.id,
					this.$route.params.history_id
				);
				this.connection.gitBillsListFlatCompact(
					'1',
					this.$store.state.current_org_id,
				);
			})
		}
		return {
			name: ref(''),
			body: ref({}),
			transfers: ref([]),
			changes: ref([]),
			document_id: ref(this.$route.params.id),
			bills_list: ref([]),
			wildcard_summ: ref(null),
			wildcard_name: ref(null),
			wildcard_comment: ref(null),
			wildcard_bill_from: ref(null),
			wildcard_bill_to: ref(null),
			is_active: ref(true),
			table_columns: ref([
				{ name: 'my_date', align: 'left', label: 'Дата', field: 'date' },
				{ name: 'my_name', align: 'left', label: 'Документ', field: 'name' },
				{ name: 'my_debet', align: 'left', label: 'Дебет', field: 'debet_start' },
				{ name: 'my_credit', align: 'left', label: 'Кредит', field: 'credit_start' },
				{ name: 'contr_date', align: 'left', label: 'Дата', field: 'debet_period' },
				{ name: 'contr_name', align: 'left', label: 'Документ', field: 'credit_period' },
				{ name: 'contr_debet', align: 'left', label: 'Дебет', field: 'debet_end' },
				{ name: 'contr_credit', align: 'left', label: 'Кредит', field: 'credit_end' },
			])
		}
	},
	methods: {
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onPrintButtonClick() {
			let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

			mywindow.document.write(`<html><head><title></title>`);
			mywindow.document.write('</head><body >' + style);
			mywindow.document.write(document.getElementById('print_content').innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/

			mywindow.print();
			mywindow.close();

			return true;
		},
		createTransferWildcard() {
			this.$q.loading.show();
			this.connection.createUpdTrasferWildcard(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.wildcard_summ,
				this.wildcard_name,
				this.wildcard_comment,
				this.wildcard_bill_from.value,
				this.wildcard_bill_to.value
			)
		},
		onTransferStateChange(value, item_id) {
			this.connection.updateTransferActiveState(
				'1',
				this.$store.state.current_org_id,
				item_id,
				value
			)
		},
		onTransferBillChange(value, item_id, direction) {
			this.connection.updateTransferBillState(
				'1',
				this.$store.state.current_org_id,
				item_id,
				value,
				direction
			)
		},
		onEditDocument() {
			this.$router.push({ name: 'document_edit_payment', params: { id: this.$route.params.id } });
		},
		onDeleteDocument() {
			this.connection.deleteDocument(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			)
		},
		onRestoreDocument() {
			this.connection.restoreDocument(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			)
		},
		onDownload() {
			this.connection.createTxtFromPayement(
				'1',
				this.$store.state.current_org_id,
				[this.$route.params.id]
			)
		}
	}
}
</script>

<style scoped>
@page {
/*size: 210mm 297mm; */
	size: 297mm 210mm;
	/* Chrome sets own margins, we change these printer settings */
	margin: 10mm 10mm 10mm 10mm;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	height: 210mm;
	width: 297mm;
	padding: 10mm 10mm 10mm 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}

table {
	width: 267mm;
	border-collapse: collapse;
	table-layout: fixed;
	margin-top: 10px;
	font-size: 8pt;
}

th,
td {
	border: 0.6mm solid black;
	min-height: 5mm;
	text-align: center;
	vertical-align: top;
}

tr {
	min-height: 5mm;
}

.aligned-cells {
	text-align: left;
	vertical-align: bottom;
}

</style>