<template>
	<div class="container">
		<h6 class="header">Отчет о движении денежных средств <q-input class="inline" dense flat v-model="document_date">
				<template v-slot:append>
					<q-icon name="event" class="cursor-pointer">
						<q-popup-proxy cover transition-show="scale" transition-hide="scale">
							<q-date mask="DD.MM.YYYY" v-model="document_date"
								@update:model-value="(newValue) => onDateChanged(newValue)">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Закрыть" color="primary" flat />
								</div>
							</q-date>
						</q-popup-proxy>
					</q-icon>
				</template>
			</q-input></h6>
		<div class="row" style="width: 180mm;">
			<div class="col-8">
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организация" class="inline" style="width: 100%;" dense flat
						v-model="organisation_name" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Идентификационный номер налогоплательщика" class="inline" style="width: 100%;" dense
						flat v-model="organisation_inn" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Вид экономической деятельности" class="inline" style="width: 100%;" dense flat
						v-model="organisation_econ" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организационно-правовая форма/форма собственности" class="inline"
						style="width: 100%;" dense flat v-model="organisation_form" />
				</p>
				<p style="width: 100%; padding-right: 10mm">Единица измерения: <q-select v-model="currency_measure"
						:options="currency_measure_options" class="inline" dense flat />
				</p>
			</div>
			<div class="col-4">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
				</table>
			</div>
		</div>

		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 90mm;">Наименование показателя</th>
						<th style="width: 45mm;">За {{ formatDate(document_date) }}г.</th>
						<th style="width: 45mm;">За 31 декабря 2023 г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Денежные потоки от текущих операций<br />Поступления - всего
						</td>
						<td><q-input class="inline" v-model="total_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="total_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							в том числе:<br />от продажи продукции, товаров, работ и услуг
						</td>
						<td><q-input class="inline" v-model="sell_goods.current" dense flat /></td>
						<td><q-input class="inline" v-model="sell_goods.previous" dense flat /></td>
					</tr>
					<tr>
						<td>арендных платежей, лицензионных платежей, роялти, комиссионных и иных аналогичных
							платежей
						</td>
						<td><q-input class="inline" v-model="rent_com_payments.current" dense flat /></td>
						<td><q-input class="inline" v-model="rent_com_payments.previous" dense flat /></td>
					</tr>
					<tr>
						<td>от перепродажи финансовых вложений</td>
						<td><q-input class="inline" v-model="resell_fin.current" dense flat /></td>
						<td><q-input class="inline" v-model="resell_fin.previous" dense flat /></td>
					</tr>
					<tr>
						<td>прочие поступления
						</td>
						<td><q-input class="inline" v-model="other_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="other_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Платежи - всего
						</td>
						<td><q-input class="inline" v-model="payments_total.current" dense flat /></td>
						<td><q-input class="inline" v-model="payments_total.previous" dense flat /></td>
					</tr>
					<tr>
						<td>в том числе:<br />поставщикам (подрядчикам) за сырье, материалы,
							работы, услуги
						</td>
						<td><q-input class="inline" v-model="payments_sender.current" dense flat /></td>
						<td><q-input class="inline" v-model="payments_sender.previous" dense flat /></td>
					</tr>
					<tr>
						<td>в связи с оплатой труда работников
						</td>
						<td><q-input class="inline" v-model="payments_salary.current" dense flat /></td>
						<td><q-input class="inline" v-model="payments_salary.previous" dense flat /></td>
					</tr>
					<tr>
						<td>процентов по долговым обязательствам
						</td>
						<td><q-input class="inline" v-model="debt_perc.current" dense flat /></td>
						<td><q-input class="inline" v-model="debt_perc.previous" dense flat /></td>
					</tr>
					<tr>
						<td>налога на прибыль организаций</td>
						<td><q-input class="inline" v-model="income_tax.current" dense flat /></td>
						<td><q-input class="inline" v-model="income_tax.previous" dense flat /></td>
					</tr>
					<tr>
						<td>прочие платежи</td>
						<td><q-input class="inline" v-model="payement_other_paymetns.current" dense flat /></td>
						<td><q-input class="inline" v-model="payement_other_paymetns.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Сальдо денежных потоков от текущих операций</td>
						<td><q-input class="inline" v-model="payment_current_saldo.current" dense flat /></td>
						<td><q-input class="inline" v-model="payment_current_saldo.previous" dense flat /></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 90mm;">Наименование показателя</th>
						<th style="width: 45mm;">За {{ formatDate(document_date) }}г.</th>
						<th style="width: 45mm;">За 31 декабря 2023 г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Денежные потоки от инвестиционных операций<br />Поступления - всего
						</td>
						<td><q-input class="inline" v-model="invest_income_total.current" dense flat /></td>
						<td><q-input class="inline" v-model="invest_income_total.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							в том числе:<br />от продажи внеоборотных активов (кроме финансовых вложений)
						</td>
						<td><q-input class="inline" v-model="sell_outbound_fin.current" dense flat /></td>
						<td><q-input class="inline" v-model="sell_outbound_fin.previous" dense flat /></td>
					</tr>
					<tr>
						<td>от продажи акций других организаций (долей участия)
						</td>
						<td><q-input class="inline" v-model="inv_sell_papers_others.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_sell_papers_others.previous" dense flat /></td>
					</tr>
					<tr>
						<td>от возврата предоставленных займов, от продажи долговых ценных бумаг (прав требования
							денежных средств к другим лицам)</td>
						<td><q-input class="inline" v-model="inv_sell_debts.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_sell_debts.previous" dense flat /></td>
					</tr>
					<tr>
						<td>дивидендов, процентов по долговым финансовым вложениям и аналогичных поступлений от долевого
							участия в других организациях</td>
						<td><q-input class="inline" v-model="inv_divid.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_divid.previous" dense flat /></td>
					</tr>
					<tr>
						<td>прочие поступления
						</td>
						<td><q-input class="inline" v-model="inv_other_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_other_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Платежи - всего
						</td>
						<td><q-input class="inline" v-model="inv_total_payments.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_total_payments.previous" dense flat /></td>
					</tr>
					<tr>
						<td>в том числе:<br />в связи с приобретением, созданием, модернизацией, реконструкцией и
							подготовкой к использованию внеоборотных активов
						</td>
						<td><q-input class="inline" v-model="inv_rec_outbound_fin.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_rec_outbound_fin.previous" dense flat /></td>
					</tr>
					<tr>
						<td>в связи с приобретением акций других организаций (долей участия)
						</td>
						<td><q-input class="inline" v-model="inv_rec_papers.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_rec_papers.previous" dense flat /></td>
					</tr>
					<tr>
						<td>в связи с приобретением долговых ценных бумаг (прав требования денежных средств к другим
							лицам), предоставление займов другим лицам
						</td>
						<td><q-input class="inline" v-model="inv_rec_debts.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_rec_debts.previous" dense flat /></td>
					</tr>
					<tr>
						<td>процентов по долговым обязательствам, включаемым в стоимость инвестиционного актива</td>
						<td><q-input class="inline" v-model="inv_rec_debts_perc.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_rec_debts_perc.previous" dense flat /></td>
					</tr>
					<tr>
						<td>прочие платежи</td>
						<td><q-input class="inline" v-model="inv_other_payments.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_other_payments.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Сальдо денежных потоков от инвестиционных операций</td>
						<td><q-input class="inline" v-model="inv_saldo.current" dense flat /></td>
						<td><q-input class="inline" v-model="inv_saldo.previous" dense flat /></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 90mm;">Наименование показателя</th>
						<th style="width: 45mm;">За {{ formatDate(document_date) }}г.</th>
						<th style="width: 45mm;">За 31 декабря 2023 г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Денежные потоки от финансовых операций<br />Поступления - всего
						</td>
						<td><q-input class="inline" v-model="fin_income_total.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_income_total.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							в том числе:<br />получение кредитов и займов
						</td>
						<td><q-input class="inline" v-model="fin_cred.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_cred.previous" dense flat /></td>
					</tr>
					<tr>
						<td>денежных вкладов собственников (участников)
						</td>
						<td><q-input class="inline" v-model="fin_invest.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_invest.previous" dense flat /></td>
					</tr>
					<tr>
						<td>от выпуска акций, увеличения долей участия</td>
						<td><q-input class="inline" v-model="fin_create_papers.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_create_papers.previous" dense flat /></td>
					</tr>
					<tr>
						<td>от выпуска облигаций, векселей и других долговых ценных бумаг и др.</td>
						<td><q-input class="inline" v-model="fin_create_oblig.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_create_oblig.previous" dense flat /></td>
					</tr>
					<tr>
						<td>прочие поступления
						</td>
						<td><q-input class="inline" v-model="fin_other_income.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_other_income.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Платежи - всего
						</td>
						<td><q-input class="inline" v-model="fin_payments_total.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_payments_total.previous" dense flat /></td>
					</tr>
					<tr>
						<td>в том числе:<br />собственникам (участникам) в связи с выкупом у них акций (долей участия)
							организации или их выходом из состава участников
						</td>
						<td><q-input class="inline" v-model="fin_pay_exit_paper.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_pay_exit_paper.previous" dense flat /></td>
					</tr>
					<tr>
						<td>на уплату дивидендов и иных платежей по распределению прибыли в пользу собственников
							(участников)
						</td>
						<td><q-input class="inline" v-model="fin_pay_div.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_pay_div.previous" dense flat /></td>
					</tr>
					<tr>
						<td>в связи с погашением (выкупом) векселей и других долговых ценных бумаг, возврат кредитов и
							займов
						</td>
						<td><q-input class="inline" v-model="fin_pay_oblig.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_pay_oblig.previous" dense flat /></td>
					</tr>
					<tr>
						<td>прочие платежи</td>
						<td><q-input class="inline" v-model="fin_pay_other_payments.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_pay_other_payments.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Сальдо денежных потоков от финансовых операций</td>
						<td><q-input class="inline" v-model="fin_saldo_fin_oper.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_saldo_fin_oper.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Сальдо денежных потоков за отчетный период</td>
						<td><q-input class="inline" v-model="fin_saldo_period.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_saldo_period.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Остаток денежных средств и денежных эквивалентов на начало отчетного периода</td>
						<td><q-input class="inline" v-model="fin_money_remainder_start.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_money_remainder_start.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Остаток денежных средств и денежных эквивалентов на конец отчетного периода</td>
						<td><q-input class="inline" v-model="fin_money_remainder_end.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_money_remainder_end.previous" dense flat /></td>
					</tr>
					<tr>
						<td>Величина влияния изменений курса иностранной валюты по отношению к рублю</td>
						<td><q-input class="inline" v-model="fin_money_rub_to_usd.current" dense flat /></td>
						<td><q-input class="inline" v-model="fin_money_rub_to_usd.previous" dense flat /></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div style="margin-top: 10mm;">
		<q-btn color="primary" icon="save" label="Сохранить" @click="onSave" />
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { nanoid } from "nanoid";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];

export default {
	name: "edit_document_report_money_move",
	data() {
		this.emitter.on("onSaveDocument", (data) => {
			console.log(data);
			this.$q.loading.hide();
			if (data.error) {
				this.$q.dialog({
					title: 'Ошибка',
					message: 'Документ не был сохранен',
					cancel: false,
					persistent: true
				}).onOk(() => {
				}).onCancel(() => {
					console.log('cancel')
				}).onDismiss(() => {
					console.log('dismiss')
				})
			} else {
				this.$q.notify('Документ был изменен');
				this.$router.back();
			}
		})
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			const body = response.data.body;
			this.organisation_name = body.organisation_name;
			this.organisation_inn = body.organisation_inn;
			this.organisation_econ = body.organisation_econ;
			this.organisation_form = body.organisation_form;
			this.document_date_unix = body.document_date_unix;
			this.document_date = body.document_date;
			this.currency_measure = body.currency_measure;
			this.total_income = body.total_income;
			this.sell_goods = body.sell_goods;
			this.rent_com_payments = body.rent_com_payments;
			this.resell_fin = body.resell_fin;
			this.other_income = body.other_income;
			this.payments_total = body.payments_total;
			this.payments_sender = body.payments_sender;
			this.payments_salary = body.payments_salary;
			this.debt_perc = body.debt_perc;
			this.income_tax = body.income_tax;
			this.payement_other_paymetns = body.payement_other_paymetns;
			this.payment_current_saldo = body.payment_current_saldo;
			this.invest_income_total = body.invest_income_total;
			this.sell_outbound_fin = body.sell_outbound_fin;
			this.inv_sell_papers_others = body.inv_sell_papers_others;
			this.inv_sell_debts = body.inv_sell_debts;
			this.inv_divid = body.inv_divid;
			this.inv_other_income = body.inv_other_income;
			this.inv_total_payments = body.inv_total_payments;
			this.inv_rec_outbound_fin = body.inv_rec_outbound_fin;
			this.inv_rec_papers = body.inv_rec_papers;
			this.inv_rec_debts = body.inv_rec_debts;
			this.inv_rec_debts_perc = body.inv_rec_debts_perc;
			this.inv_other_payments = body.inv_other_payments;
			this.inv_saldo = body.inv_saldo;
			this.fin_income_total = body.fin_income_total;
			this.fin_cred = body.fin_cred;
			this.fin_invest = body.fin_invest;
			this.fin_create_papers = body.fin_create_papers;
			this.fin_create_oblig = body.fin_create_oblig;
			this.fin_other_income = body.fin_other_income;
			this.fin_payments_total = body.fin_payments_total;
			this.fin_pay_exit_paper = body.fin_pay_exit_paper;
			this.fin_pay_div = body.fin_pay_div;
			this.fin_pay_oblig = body.fin_pay_oblig;
			this.fin_pay_other_payments = body.fin_pay_other_payments;
			this.fin_saldo_fin_oper = body.fin_saldo_fin_oper;
			this.fin_saldo_period = body.fin_saldo_period;
			this.fin_money_remainder_start = body.fin_money_remainder_start;
			this.fin_money_remainder_end = body.fin_money_remainder_end;
			this.fin_money_rub_to_usd = body.fin_money_rub_to_usd;

			this.in_org_number = body.in_org_number;
			this.document_date = moment.unix(body.document_date_unix).format('DD.MM.YYYY');
			this.document_date_unix = body.document_date_unix;
		});
		this.emitter.on('didReceiveAgentInfoByInn', (data) => {
			console.log(data, 'agent')
			if (data.request_id === 'rec_inn') {
				this.rec = data.info.name.short_with_opf;
				this.rec_kpp = data.info.kpp;
			}
			if (data.request_id === 'plat_inn') {
				this.plat = data.info.name.short_with_opf;
				this.plat_kpp = data.info.kpp;
			}
		})
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				nanoid(),
				this.$store.state.current_org_id,
				this.$route.params.id
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					nanoid(),
					this.$store.state.current_org_id,
					this.$route.params.id
				);
			})
		}
		return {
			step: ref(1),
			document_type: ref('report'),
			in_org_number: ref(null),
			organisation_name: ref(null),
			organisation_inn: ref(null),
			organisation_econ: ref(null),
			organisation_form: ref(null),
			document_date_unix: ref(0),
			document_date: ref(null),
			currency_measure_options: ref(currency_measure_options),
			currency_measure: ref(currency_measure_options[0]),
			total_income: ref({ current: 0, previous: 0, desc: '' }),
			sell_goods: ref({ current: 0, previous: 0, desc: '' }),
			rent_com_payments: ref({ current: 0, previous: 0, desc: '' }),
			resell_fin: ref({ current: 0, previous: 0, desc: '' }),
			other_income: ref({ current: 0, previous: 0, desc: '' }),
			payments_total: ref({ current: 0, previous: 0, desc: '' }),
			payments_sender: ref({ current: 0, previous: 0, desc: '' }),
			payments_salary: ref({ current: 0, previous: 0, desc: '' }),
			debt_perc: ref({ current: 0, previous: 0, desc: '' }),
			income_tax: ref({ current: 0, previous: 0, desc: '' }),
			payement_other_paymetns: ref({ current: 0, previous: 0, desc: '' }),
			payment_current_saldo: ref({ current: 0, previous: 0, desc: '' }),
			invest_income_total: ref({ current: 0, previous: 0, desc: '' }),
			sell_outbound_fin: ref({ current: 0, previous: 0, desc: '' }),
			inv_sell_papers_others: ref({ current: 0, previous: 0, desc: '' }),
			inv_sell_debts: ref({ current: 0, previous: 0, desc: '' }),
			inv_divid: ref({ current: 0, previous: 0, desc: '' }),
			inv_other_income: ref({ current: 0, previous: 0, desc: '' }),
			inv_total_payments: ref({ current: 0, previous: 0, desc: '' }),
			inv_rec_outbound_fin: ref({ current: 0, previous: 0, desc: '' }),
			inv_rec_papers: ref({ current: 0, previous: 0, desc: '' }),
			inv_rec_debts: ref({ current: 0, previous: 0, desc: '' }),
			inv_rec_debts_perc: ref({ current: 0, previous: 0, desc: '' }),
			inv_other_payments: ref({ current: 0, previous: 0, desc: '' }),
			inv_saldo: ref({ current: 0, previous: 0, desc: '' }),
			fin_income_total: ref({ current: 0, previous: 0, desc: '' }),
			fin_cred: ref({ current: 0, previous: 0, desc: '' }),
			fin_invest: ref({ current: 0, previous: 0, desc: '' }),
			fin_create_papers: ref({ current: 0, previous: 0, desc: '' }),
			fin_create_oblig: ref({ current: 0, previous: 0, desc: '' }),
			fin_other_income: ref({ current: 0, previous: 0, desc: '' }),
			fin_payments_total: ref({ current: 0, previous: 0, desc: '' }),
			fin_pay_exit_paper: ref({ current: 0, previous: 0, desc: '' }),
			fin_pay_div: ref({ current: 0, previous: 0, desc: '' }),
			fin_pay_oblig: ref({ current: 0, previous: 0, desc: '' }),
			fin_pay_other_payments: ref({ current: 0, previous: 0, desc: '' }),
			fin_saldo_fin_oper: ref({ current: 0, previous: 0, desc: '' }),
			fin_saldo_period: ref({ current: 0, previous: 0, desc: '' }),
			fin_money_remainder_start: ref({ current: 0, previous: 0, desc: '' }),
			fin_money_remainder_end: ref({ current: 0, previous: 0, desc: '' }),
			fin_money_rub_to_usd: ref({ current: 0, previous: 0, desc: '' }),
		}
	},
	methods: {
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		checkAgentBy(bik_val, field_id) {
			const bik = bik_val.replace(/\s/g, "");
			console.log(bik, field_id)
			if (bik.length < 9) {
				return;
			}
			this.connection.getAgentInfoByBik(
				field_id,
				bik
			);
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		checkAgentByInn(inn_val, field_id) {
			const inn = inn_val.replace(/\s/g, "");
			console.log(inn, field_id)
			if (inn.length < 9) {
				return;
			}
			this.connection.getAgentInfoByInn(
				field_id,
				inn
			);
		},
		onSave() {
			const body = {
				organisation_name: this.organisation_name,
				organisation_inn: this.organisation_inn,
				organisation_econ: this.organisation_econ,
				organisation_form: this.organisation_form,
				currency_measure: this.currency_measure,
				total_income: this.total_income,
				sell_goods: this.sell_goods,
				rent_com_payments: this.rent_com_payments,
				resell_fin: this.resell_fin,
				other_income: this.other_income,
				payments_total: this.payments_total,
				payments_sender: this.payments_sender,
				payments_salary: this.payments_salary,
				debt_perc: this.debt_perc,
				income_tax: this.income_tax,
				payement_other_paymetns: this.payement_other_paymetns,
				payment_current_saldo: this.payment_current_saldo,
				invest_income_total: this.invest_income_total,
				sell_outbound_fin: this.sell_outbound_fin,
				inv_sell_papers_others: this.inv_sell_papers_others,
				inv_sell_debts: this.inv_sell_debts,
				inv_divid: this.inv_divid,
				inv_other_income: this.inv_other_income,
				inv_total_payments: this.inv_total_payments,
				inv_rec_outbound_fin: this.inv_rec_outbound_fin,
				inv_rec_papers: this.inv_rec_papers,
				inv_rec_debts: this.inv_rec_debts,
				inv_rec_debts_perc: this.inv_rec_debts_perc,
				inv_other_payments: this.inv_other_payments,
				inv_saldo: this.inv_saldo,
				fin_income_total: this.fin_income_total,
				fin_cred: this.fin_cred,
				fin_invest: this.fin_invest,
				fin_create_papers: this.fin_create_papers,
				fin_create_oblig: this.fin_create_oblig,
				fin_other_income: this.fin_other_income,
				fin_payments_total: this.fin_payments_total,
				fin_pay_exit_paper: this.fin_pay_exit_paper,
				fin_pay_div: this.fin_pay_div,
				fin_pay_oblig: this.fin_pay_oblig,
				fin_pay_other_payments: this.fin_pay_other_payments,
				fin_saldo_fin_oper: this.fin_saldo_fin_oper,
				fin_saldo_period: this.fin_saldo_period,
				fin_money_remainder_start: this.fin_money_remainder_start,
				fin_money_remainder_end: this.fin_money_remainder_end,
				fin_money_rub_to_usd: this.fin_money_rub_to_usd,
				document_date: this.document_date_unix,
				in_org_number: this.in_org_number,
				document_type: 'report',
				report_type: 'money_move'
			};
			this.$q.loading.show();
			this.connection.createNewDocument(
				'3',
				this.$store.state.current_org_id,
				body
			);

		}

	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	/* width: 210mm;
	height: 297mm; */
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
}
</style>