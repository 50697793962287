<template>

	<div class="">
		<q-btn @click="onPrintButtonClick" unelevated color="white" icon-right="download" text-color="primary"
			label="Печать" />
		<q-btn @click="onEditDocument" unelevated color="white" icon-right="edit" text-color="primary"
			label="Редактирование" />
		<q-btn v-if="is_active" @click="onDeleteDocument" unelevated color="white" icon-right="delete"
			text-color="negative" label="Удалить" />
		<q-btn v-else @click="onRestoreDocument" unelevated color="white" icon-right="history" text-color="primary"
			label="Восстановить" />
	</div>
	<div class="doc" id="print_content">
		<h6 class="header" style="font-size: 16pt; width: 100%; text-align: center;">Бухгалтерский баланс на {{
			formatDate(body.date_current) }}г. </h6>
		<div style="width: 180mm; display: flex; flex-wrap: wrap;">
			<div style="display: inline; width: 120mm;">
				<p style="width: 100%; padding-right: 10mm">Организация: {{ body.organisation_name }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Идентификационный номер налогоплательщика: {{
					body.organisation_inn }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Вид экономической деятельности: {{
					body.organisation_econ }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Организационно-правовая форма/форма собственности: {{ body.organisation_form }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Единица измерения: {{ ((body.currency_measure || { label: '' }).label) }}
				</p>
			</div>
			<div style="display: inline; width: 60mm; float: right;">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"></td>
						<td colspan="2"></td>
						<td colspan="2"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"></td>
						<td colspan="3"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"></td>
					</tr>
				</table>
			</div>
		</div>
		<div class="section">
			<p style="width: 100%; padding-right: 0mm">
				Местонахождение (адрес): {{ body.organisation_address }}
			</p>
		</div>
		<div class="section">
			<div style=" padding-right: 0mm; margin-bottom: 8mm; display: inline;">
				<div style="display: inline-block; ">Бухгалтерская отчетность подлежит обязательному аудиту
				</div>
				<div style="display: inline-block; float: right;">
					<div
						style="text-align: center; font-weight: bold;display: inline-block;  width: 6mm; height: 8mm; border: 0.3mm black solid;">
						{{ body.audit ?
						'X' : '&nbsp;'
						}}</div> Да
					<div
						style="text-align: center; font-weight: bold; display: inline-block; width: 6mm; height: 8mm; margin-left: 2mm; border: 0.3mm black solid;">
						{{ body.audit ?
						'&nbsp;' : 'X' }}</div> Нет
				</div>

			</div>
		</div>
		<div class="section">
			<div style="width: 100%; margin-bottom: 4mm; margin-top: 4mm; padding-right: 0mm">
				Наименование аудиторской организации/
				фамилия, имя, отчество (при наличии) индивидуального аудитора: {{ body.organisation_audit_name }}
			</div>
			<div style="width: 180mm;">
				<div style="display: inline-block; width: 120mm;">
					<div>
						<div style="width: 100%; margin-bottom: 4mm; padding-right: 0mm">
							Идентификационный номер налогоплательщика аудиторской
							организации/индивидуального аудитора:
						</div>
						<div style="width: 100%; margin-bottom: 4mm; padding-right: 0mm">
							Основной государственный регистрационный номер аудиторской
							организации/индивидуального аудитора:
						</div>
					</div>
				</div>
				<div style="display: inline-block; width: 60mm; float: right;">
					<table
						style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">

						<tr>
							<td colspan="1"
								style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white; height: 13mm;">
								ИНН
							</td>
							<td colspan="1">{{ body.organisation_audit_id }}</td>
						</tr>
						<tr>
							<td colspan="1"
								style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white; height: 13mm;">
								ОГРН/ОГРНИП
							</td>
							<td colspan="1">{{ body.organisation_audit_num }}</td>
						</tr>

					</table>
				</div>
			</div>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 25mm;">Пояснения</th>
						<th style="width: 80mm;">Наименование показателя</th>
						<th style="width: 25mm;">На {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 25mm;">На {{ formatDate(body.date_previous) }}г.</th>
						<th style="width: 25mm;">На {{ formatDate(body.date_preprevious) }}г.</th>
					</tr>
				</thead>
				<tbody>

					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							АКТИВ</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
					</tr>

					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							I. ВНЕОБОРОТНЫЕ АКТИВЫ
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
					</tr>
					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.nematerial_i.desc }}
						</td>
						<td style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: left;">
							Нематериальные
							активы
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.nematerial_i.current }}
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.nematerial_i.previous }}
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.nematerial_i.preprevious }}
						</td>
					</tr>
					<tr>
						<td>
							{{ body.dev_result_i.desc }}
						</td>
						<td style="text-align: left">Результаты исследований и разработок
						</td>
						<td>{{ body.dev_result_i.current }}</td>
						<td>{{ body.dev_result_i.previous }}</td>
						<td>{{ body.dev_result_i.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.nonmat_assets_i.desc }}
						</td>
						<td style="text-align: left">Нематериальные поисковые активы
						</td>
						<td>{{ body.nonmat_assets_i.current }}</td>
						<td>{{ body.nonmat_assets_i.previous }}</td>
						<td>{{ body.nonmat_assets_i.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.mat_act_i.desc }}
						</td>
						<td style="text-align: left">Материальные поисковые активы
						</td>
						<td>{{ body.mat_act_i.current }}</td>
						<td>{{ body.mat_act_i.previous }}</td>
						<td>{{ body.mat_act_i.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.fix_assets_i.desc }}
						</td>
						<td style="text-align: left">Основные средства</td>
						<td>{{ body.fix_assets_i.current }}</td>
						<td>{{ body.fix_assets_i.previous }}</td>
						<td>{{ body.fix_assets_i.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.mat_assets_i.desc }}
						</td>
						<td style="text-align: left">Доходные вложения в материальные ценности
						</td>
						<td>{{ body.mat_assets_i.current }}</td>
						<td>{{ body.mat_assets_i.previous }}</td>
						<td>{{ body.mat_assets_i.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.fin_income_i.desc }}
						</td>
						<td style="text-align: left">Финансовые вложения
						</td>
						<td>{{ body.fin_income_i.current }}</td>
						<td>{{ body.fin_income_i.previous }}</td>
						<td>{{ body.fin_income_i.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.tax_active_i.desc }}
						</td>
						<td style="text-align: left">Отложенные налоговые активы
						</td>
						<td>{{ body.tax_active_i.current }}</td>
						<td>{{ body.tax_active_i.previous }}</td>
						<td>{{ body.tax_active_i.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.other_assets_i.desc }}
						</td>
						<td style="text-align: left">Прочие внеоборотные активы
						</td>
						<td>{{ body.other_assets_i.current }}</td>
						<td>{{ body.other_assets_i.previous }}</td>
						<td>{{ body.other_assets_i.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.total_i.desc }}
						</td>
						<td>Итого по разделу I
						</td>
						<td>{{ body.total_i.current }}</td>
						<td>{{ body.total_i.previous }}</td>
						<td>{{ body.total_i.preprevious }}</td>
					</tr>
					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							II. ОБОРОТНЫЕ АКТИВЫ
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
					</tr>
					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.stocks_ii.desc }}
						</td>
						<td style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: left;">
							Запасы</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.stocks_ii.current }}
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.stocks_ii.previous }}
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.stocks_ii.preprevious }}
						</td>
					</tr>
					<tr>
						<td>
							{{ body.tax_nds_ii.desc }}
						</td>
						<td style="text-align: left">Налог на добавленную стоимость по приобретенным ценностям</td>
						<td>{{ body.tax_nds_ii.current }}</td>
						<td>{{ body.tax_nds_ii.previous }}</td>
						<td>{{ body.tax_nds_ii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.deb_dolg_ii.desc }}
						</td>
						<td style="text-align: left">Дебиторская задолженность</td>
						<td>{{ body.deb_dolg_ii.current }}</td>
						<td>{{ body.deb_dolg_ii.previous }}</td>
						<td>{{ body.deb_dolg_ii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.fin_income_ii.desc }}
						</td>
						<td style="text-align: left">Финансовые вложения (за исключением денежных эквивалентов)</td>
						<td>{{ body.fin_income_ii.current }}</td>
						<td>{{ body.fin_income_ii.previous }}</td>
						<td>{{ body.fin_income_ii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.money_ii.desc }}
						</td>
						<td style="text-align: left">Денежные средства и денежные эквиваленты</td>
						<td>{{ body.money_ii.current }}</td>
						<td>{{ body.money_ii.previous }}</td>
						<td>{{ body.money_ii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.other_assets_ii.desc }}
						</td>
						<td style="text-align: left">Прочие оборотные активы</td>
						<td>{{ body.other_assets_ii.current }}</td>
						<td>{{ body.other_assets_ii.previous }}</td>
						<td>{{ body.other_assets_ii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.total_ii.desc }}
						</td>
						<td>Итого по разделу II</td>
						<td>{{ body.total_ii.current }}</td>
						<td>{{ body.total_ii.previous }}</td>
						<td>{{ body.total_ii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.active_balance.desc }}
						</td>
						<td style="font-weight: bold;">БАЛАНС</td>
						<td>{{ body.active_balance.current }}</td>
						<td>{{ body.active_balance.previous }}</td>
						<td>{{ body.active_balance.preprevious }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 25mm;">Пояснения</th>
						<th style="width: 80mm;">Наименование показателя</th>
						<th style="width: 25mm;">На {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 25mm;">На {{ formatDate(body.date_previous) }}г.</th>
						<th style="width: 25mm;">На {{ formatDate(body.date_preprevious) }}г.</th>
					</tr>
				</thead>
				<tbody>

					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							ПАССИВ</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
					</tr>

					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							III. КАПИТАЛ И РЕЗЕРВЫ 6)
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
					</tr>
					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.auth_cap_iii.desc }}
						</td>
						<td style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: left">
							Уставный капитал
							(складочный капитал, уставный фонд, вклады товарищей)
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.auth_cap_iii.current }}
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.auth_cap_iii.previous }}
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.auth_cap_iii.preprevious }}
						</td>
					</tr>
					<tr>
						<td>
							{{ body.self_prom_iii.desc }}
						</td>
						<td style="text-align: left">Собственные акции, выкупленные у акционеров
						</td>
						<td>{{ body.self_prom_iii.current }}</td>
						<td>{{ body.self_prom_iii.previous }}</td>
						<td>{{ body.self_prom_iii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.reval_assets_iii.desc }}
						</td>
						<td style="text-align: left">Переоценка внеоборотных активов
						</td>
						<td>{{ body.reval_assets_iii.current }}</td>
						<td>{{ body.reval_assets_iii.previous }}</td>
						<td>{{ body.reval_assets_iii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.add_cap_iii.desc }}
						</td>
						<td style="text-align: left">Добавочный капитал (без переоценки)
						</td>
						<td>{{ body.add_cap_iii.current }}</td>
						<td>{{ body.add_cap_iii.previous }}</td>
						<td>{{ body.add_cap_iii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.rezerv_kap_iii.desc }}
						</td>
						<td style="text-align: left">Резервный капитал
						</td>
						<td>{{ body.rezerv_kap_iii.current }}</td>
						<td>{{ body.rezerv_kap_iii.previous }}</td>
						<td>{{ body.rezerv_kap_iii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.ret_earn_iii.desc }}
						</td>
						<td style="text-align: left">Нераспределенная прибыль (непокрытый убыток)
						</td>
						<td>{{ body.ret_earn_iii.current }}</td>
						<td>{{ body.ret_earn_iii.previous }}</td>
						<td>{{ body.ret_earn_iii.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.total_iii.desc }}
						</td>
						<td style="text-align: left">Итого по разделу III
						</td>
						<td>{{ body.total_iii.current }}</td>
						<td>{{ body.total_iii.previous }}</td>
						<td>{{ body.total_iii.preprevious }}</td>
					</tr>
					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							IV. ДОЛГОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
					</tr>
					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.cred_money_iv.desc }}
						</td>
						<td style="border-top: 0px solid white; border-bottom: 0px solid white;">Заемные средства
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.cred_money_iv.current }}
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.cred_money_iv.previous }}
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.cred_money_iv.preprevious }}
						</td>
					</tr>
					<tr>
						<td>
							{{ body.delayed_tax_iv.desc }}
						</td>
						<td style="text-align: left">Отложенные налоговые обязательства</td>
						<td>{{ body.delayed_tax_iv.current }}</td>
						<td>{{ body.delayed_tax_iv.previous }}</td>
						<td>{{ body.delayed_tax_iv.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.est_liab_iv.desc }}
						</td>
						<td style="text-align: left">Оценочные обязательства</td>
						<td>{{ body.est_liab_iv.current }}</td>
						<td>{{ body.est_liab_iv.previous }}</td>
						<td>{{ body.est_liab_iv.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.other_oblig_iv.desc }}
						</td>
						<td style="text-align: left">Прочие обязательства</td>
						<td>{{ body.other_oblig_iv.current }}</td>
						<td>{{ body.other_oblig_iv.previous }}</td>
						<td>{{ body.other_oblig_iv.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.total_iv.desc }}
						</td>
						<td style="text-align: left">Итого по разделу IV</td>
						<td>{{ body.total_iv.current }}</td>
						<td>{{ body.total_iv.previous }}</td>
						<td>{{ body.total_iv.preprevious }}</td>
					</tr>
					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							V. КРАТКОСРОЧНЫЕ ОБЯЗАТЕЛЬСТВА
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
						</td>
					</tr>
					<tr>
						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.dolg_v.desc }}
						</td>
						<td style="border-top: 0px solid white; border-bottom: 0px solid white;">Заемные средства
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.dolg_v.current }}
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.dolg_v.previous }}
						</td>

						<td
							style="border-top: 0px solid white; border-bottom: 0px solid white; text-align: center; font-weight: bold;">
							{{ body.dolg_v.preprevious }}
						</td>
					</tr>
					<tr>
						<td>
							{{ body.cred_bill_v.desc }}
						</td>
						<td style="text-align: left">Кредиторская задолженность</td>
						<td>{{ body.cred_bill_v.current }}</td>
						<td>{{ body.cred_bill_v.previous }}</td>
						<td>{{ body.cred_bill_v.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.fut_income_v.desc }}
						</td>
						<td style="text-align: left">Доходы будущих периодов</td>
						<td>{{ body.fut_income_v.current }}</td>
						<td>{{ body.fut_income_v.previous }}</td>
						<td>{{ body.fut_income_v.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.est_liab_v.desc }}
						</td>
						<td style="text-align: left">Оценочные обязательства</td>
						<td>{{ body.est_liab_v.current }}</td>
						<td>{{ body.est_liab_v.previous }}</td>
						<td>{{ body.est_liab_v.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.other_oblig_v.desc }}
						</td>
						<td style="text-align: left">Прочие обязательства</td>
						<td>{{ body.other_oblig_v.current }}</td>
						<td>{{ body.other_oblig_v.previous }}</td>
						<td>{{ body.other_oblig_v.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.total_v.desc }}
						</td>
						<td>Итого по разделу V</td>
						<td>{{ body.total_v.current }}</td>
						<td>{{ body.total_v.previous }}</td>
						<td>{{ body.total_v.preprevious }}</td>
					</tr>
					<tr>
						<td>
							{{ body.passive_balance.desc }}
						</td>
						<td style="font-weight: bold;">БАЛАНС</td>
						<td>{{ body.passive_balance.current }}</td>
						<td>{{ body.passive_balance.previous }}</td>
						<td>{{ body.passive_balance.preprevious }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import moment from "moment";

var style = "<style>@page{size: A4;margin: 10}.doc{margin: 0;padding: 0;font-family: 'Times New Roman', Times, serif;font-size: 12px;background: white;color: black;width: 210mm;padding: 15mm;box-sizing: border-box;display: block;margin: auto;position: relative}.header{text-align: center}.section{margin: 20px 0}table{width: 100%;border-collapse: collapse}table,th,td{border: 0.3mm solid black;padding: 0px 4px;text-align: center}.right-align{text-align: right}.signature{margin-top: 20px;text-align: right}@media print{@page{size: A4;margin: 20mm}body{margin: 0;font-size: 12pt}table,th,td{border: 1px solid black;padding: 5px}.header{margin-top: 0}.pagebreak{page-break-before: always}}</style>";
const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];
export default {
	name: "info_document_report_balance",
	data() {
		this.emitter.on('onDeleteDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			);
		});

		this.emitter.on('onRestoreDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			);
		});
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			// const changes = response.data.changes;
			console.log(response.body);
			this.show = true;
			this.is_active = response.data.is_active;
			this.name = response.data.name;
			this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
			this.changes = response.data.changes;
			this.transfers = response.data.transfers;
			this.$q.loading.hide();
		});
		this.emitter.on('onCreateUpdTransferWildcard', (data) => {
			console.log(data)
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.$route.params.history_id
			);
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element) => {
				return { label: element.full_name, value: element.id }
			});
		});
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.$route.params.history_id
			);
			this.connection.gitBillsListFlatCompact(
				'1',
				this.$store.state.current_org_id,
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					'1',
					this.$store.state.current_org_id,
					this.$route.params.id,
					this.$route.params.history_id
				);
				this.connection.gitBillsListFlatCompact(
					'1',
					this.$store.state.current_org_id,
				);
			})
		}
		return {
			show: ref(false),
			name: ref(''),
			body: ref({
				organisation_name: null,
				organisation_inn: null,
				organisation_econ: null,
				organisation_form: null,
				organisation_address: null,
				organisation_audit_id: null,
				organisation_audit_name: null,
				organisation_audit_num: null,
				audit: false,
				document_date_unix: 0,
				document_date: null,
				currency_measure_options: currency_measure_options,
				currency_measure: currency_measure_options[0],
				nematerial_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				dev_result_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				nonmat_assets_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				mat_act_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				fix_assets_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				mat_assets_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				fin_income_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				tax_active_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				other_assets_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				total_i: { current: 0, previous: 0, preprevious: 0, desc: '' },
				stocks_ii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				tax_nds_ii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				deb_dolg_ii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				fin_income_ii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				money_ii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				other_assets_ii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				total_ii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				active_balance: { current: 0, previous: 0, preprevious: 0, desc: '' },
				auth_cap_iii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				self_prom_iii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				reval_assets_iii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				add_cap_iii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				rezerv_kap_iii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				ret_earn_iii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				total_iii: { current: 0, previous: 0, preprevious: 0, desc: '' },
				cred_money_iv: { current: 0, previous: 0, preprevious: 0, desc: '' },
				delayed_tax_iv: { current: 0, previous: 0, preprevious: 0, desc: '' },
				est_liab_iv: { current: 0, previous: 0, preprevious: 0, desc: '' },
				other_oblig_iv: { current: 0, previous: 0, preprevious: 0, desc: '' },
				total_iv: { current: 0, previous: 0, preprevious: 0, desc: '' },
				dolg_v: { current: 0, previous: 0, preprevious: 0, desc: '' },
				cred_bill_v: { current: 0, previous: 0, preprevious: 0, desc: '' },
				fut_income_v: { current: 0, previous: 0, preprevious: 0, desc: '' },
				est_liab_v: { current: 0, previous: 0, preprevious: 0, desc: '' },
				other_oblig_v: { current: 0, previous: 0, preprevious: 0, desc: '' },
				total_v: { current: 0, previous: 0, preprevious: 0, desc: '' },
				passive_balance: { current: 0, previous: 0, preprevious: 0, desc: '' },
				date_current: 0,
				date_previous: 0,
                date_preprevious: 0
			}),
			is_active: ref(true),
			transfers: ref([]),
			changes: ref([]),
			document_id: ref(this.$route.params.id),
			bills_list: ref([]),
			wildcard_summ: ref(null),
			wildcard_name: ref(null),
			wildcard_comment: ref(null),
			wildcard_bill_from: ref(null),
			wildcard_bill_to: ref(null)
		}
	},
	methods: {
		onTransferStateChange(value, item_id) {
			this.connection.updateTransferActiveState(
				'1',
				this.$store.state.current_org_id,
				item_id,
				value
			)
		},
		onTransferBillChange(value, item_id, direction) {
			this.connection.updateTransferBillState(
				'1',
				this.$store.state.current_org_id,
				item_id,
				value,
				direction
			)
		},
		valueForTable(array, id, key) {
			return array[id][key].label || array[id][key].value
		},
		valueFor(array, key) {
			return array.find(item => item.field_id === key).value;
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onPrintButtonClick() {
			let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

			mywindow.document.write(`<html><head><title></title>`);
			mywindow.document.write('</head><body >' + style);
			mywindow.document.write(document.getElementById('print_content').innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/

			mywindow.print();
			mywindow.close();

			return true;
		},
		createTransferWildcard() {
			this.$q.loading.show();
			this.connection.createUpdTrasferWildcard(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.wildcard_summ,
				this.wildcard_name,
				this.wildcard_comment,
				this.wildcard_bill_from.value,
				this.wildcard_bill_to.value
			)
		},
		onEditDocument() {
			this.$router.push({ name: 'reports_edit_balance', params: { id: this.$route.params.id } });
		},
		onDeleteDocument() {
			this.connection.deleteDocument(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			)
		},
		onRestoreDocument() {
			this.connection.restoreDocument(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			)
		}
	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 10;
}

.doc {
	margin: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	width: 210mm;
	
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
	word-wrap: break-word;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: center;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
	.pagebreak {
		page-break-before: always;
	}
}
</style>