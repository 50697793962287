<template>
	<div class="q-pa-md">
		<div class="row">
			<div class="col-4">
				<div class="text-h6">
					Контрагент
				</div>
				<div class="q-pa-sm">
					<q-input style="max-width: 200px;" dense mask="## ## ###### ##" v-model="selected_inn"
						label="ИНН / КПП" @update:model-value="checkAgentBy(selected_inn)" />
				</div>
			</div>
			<div class="col-4">
				<div class="text-h6">
					Период
				</div>
				<div class="inline-block">
					<q-input style="width: 200px" label="Начало периода" flat v-model="period_start" mask="date"
						:rules="['date']">
						<template v-slot:append>
							<q-icon name="event" class="cursor-pointer">
								<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
									<q-date v-model="period_start">
										<div class="row items-center justify-end">
											<q-btn v-close-popup label="Закрыть" color="primary" flat />
										</div>
									</q-date>
								</q-popup-proxy>
							</q-icon>
						</template>
					</q-input>
				</div>
				<div class="inline-block">
					<q-input style="width: 200px" label="Конец периода" flat v-model="period_end" mask="date"
						:rules="['date']">
						<template v-slot:append>
							<q-icon name="event" class="cursor-pointer">
								<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
									<q-date v-model="period_end">
										<div class="row items-center justify-end">
											<q-btn v-close-popup label="Закрыть" color="primary" flat />
										</div>
									</q-date>
								</q-popup-proxy>
							</q-icon>
						</template>
					</q-input>
				</div>
			</div>

			<div class="col-4">

			</div>
		</div>
		<div>
			<div class="inline-block q-pa-sm">
				<q-btn :disable="!can_request_act" @click="requestAct" unelevated color="primary" icon-right="code"
					text-color="white" label="Сформировать" />
			</div>
			<!-- <div class="inline-block q-pa-sm">
				<q-btn @click="onMakePdf" unelevated color="white" icon-right="download" text-color="primary"
					label="Печать" />
			</div> -->
		</div>


		<div id="print_content">
			<q-table v-show="show_table" :title="'Акт сверки взаиморасчетов'" :rows="table_rows"
				:columns="table_columns" row-key="name" flat separator="cell" :pagination="[0]" v-model="table_rows"
				:loading="isUpdatingData" loading-label="Обновление данных"
				no-data-label="Данные не загружены. Измените период или ИНН контрагента">
				<template v-slot:header="props">
					<q-tr>

						<q-th colspan="2">
							По данным {{ my_org_name }}
						</q-th>
						<q-th colspan="2">
							Российский рубль
						</q-th>
						<q-th colspan="2">
							По данным {{ contr_org_name }}
						</q-th>
						<q-th colspan="2">
							Российский рубль
						</q-th>
					</q-tr>
					<q-tr :props="props">
						<q-th v-for="col in props.cols" :key="col.name" :props="props">
							{{ col.label }}
						</q-th>
					</q-tr>
					<q-tr>

						<q-th colspan="2">
							Сальдо начальное
						</q-th>
						<q-th colspan="1">
							{{ total_before.my_total_debet }}
						</q-th>
						<q-th colspan="1">
							{{ total_before.my_total_credit }}
						</q-th>
						<q-th colspan="2">
							Сальдо начальное
						</q-th>
						<q-th colspan="1">
							{{ total_before.contr_total_debet }}
						</q-th>
						<q-th colspan="1">
							{{ total_before.contr_total_credit }}
						</q-th>
					</q-tr>
				</template>
				<template v-slot:bottom-row>
					<q-tr>
						<q-th colspan="2">
							Сальдо конечное
						</q-th>
						<q-th colspan="1">
							{{ total.my_total_debet }}
						</q-th>
						<q-th colspan="1">
							{{ total.my_total_credit }}
						</q-th>
						<q-th colspan="2">
							Сальдо конечное
						</q-th>
						<q-th colspan="1">
							{{ total.contr_total_debet }}
						</q-th>
						<q-th colspan="1">
							{{ total.contr_total_credit }}
						</q-th>
					</q-tr>
				</template>
			</q-table>
		</div>

	</div>
	<!-- </div>
  </div> -->
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const table_columns = [
	{ name: 'my_date', align: 'left', label: 'Дата', field: 'my_date' },
	{ name: 'my_name', align: 'left', label: 'Документ', field: 'my_name' },
	{ name: 'my_debet', align: 'left', label: 'Дебет', field: 'my_debet' },
	{ name: 'my_credit', align: 'left', label: 'Кредит', field: 'my_credit' },
	{ name: 'contr_date', align: 'left', label: 'Дата', field: 'contr_date' },
	{ name: 'contr_name', align: 'left', label: 'Документ', field: 'contr_name' },
	{ name: 'contr_debet', align: 'left', label: 'Дебет', field: 'contr_debet' },
	{ name: 'contr_credit', align: 'left', label: 'Кредит', field: 'contr_credit' },
]


const summary = {
	debet_end: 0,
	credit_end: 0,
	saldo_debet_end: 0,
	saldo_debet_end_count: 0,
	saldo_credit_end: 0,
	saldo_credit_end_count: 0
}

export default {
	name: "act_check_master",
	data() {
		this.emitter.on('didReceoveUserInfoAcrossOrg', (data) => {
			const index = data.user.organisations.findIndex((x) => x.id == this.$store.state.current_org_id);
			this.my_org_name = data.user.organisations[index].name;
		});
		this.emitter.on('didReceiveAgentInfoByInn', (data) => {
			console.log(data);
			if (data.error === undefined) {
				this.contr_org_name = data.info.name.short_with_opf;
				this.can_request_act = true;
			} else {
				this.can_request_act = false;
			}
			
		})
		this.emitter.on('onCreateActSver', (response) => {
			const data = response.data;
            if (response.type === 'result') {
                this.show_table = true;
                this.table_rows = data.rows;
                this.total = data.total;
				this.total_before = data.total_before;
            } else {
				this.show_table = false;
			}
		});
		if (this.connection.isAuthenticated) {
			this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
			});
		}
		return {
			step: ref(1),
			period_start_proxy: ref(this.$store.state.filter_period.from),
			period_end_proxy: ref(this.$store.state.filter_period.to),
			period_start: ref(this.$store.state.filter_period.from),
			period_end: ref(this.$store.state.filter_period.to),
			isUpdatingData: ref(false),
			table_columns: ref(table_columns),
			table_rows: ref([]),
			summary: ref(summary),
			bill_name: ref(''),
			first_level_filter_values: ref(['__all']),
			first_level_filter: ref({ value: '__all', label: 'Без фильтрации' }),
			bill_id: ref(this.$route.params.id),
			selected_bill: ref(null),
			bills_list_options: ref([]),
			my_org_name: ref(null),
			contr_org_name: ref('не выбрано'),
			selected_inn: ref(null),
			show_table: ref(false),
			can_request_act: ref(false),
			total: ref({
				'my_total_debet': 0,
				'my_total_credit': 0,
				'contr_total_debet': 0,
				'contr_total_credit': 0,
			}),
			total_before: ref({
				'my_total_debet': 0,
				'my_total_credit': 0,
				'contr_total_debet': 0,
				'contr_total_credit': 0,
			})
		}
	},
	methods: {
		safeValue(value) {
			return value === '' ? '&nbsp;' : value
		},
		updateFilterStartProxy() {
			this.period_start_proxy = this.period_start
		},
		updateFilterEndProxy() {
			this.period_start_proxy = this.period_start
		},
		filterSaveStartProxy() {
			this.period_start = this.period_start_proxy;
			this.period_start_unix = moment(this.period_start_proxy).endOf('day').unix();
		},
		filterSaveEndProxy() {
			this.period_end = this.period_end_proxy;
			this.period_end_unix = moment(this.period_end_proxy).endOf('day').unix();
		},
		requestAct() {
			const inn = this.selected_inn.replace(/\s/g, "");
			if (inn.length < 9) {
				return;
			}
			const additional_data = {
				my_org_name: this.my_org_name,
				contr_org_name: this.contr_org_name,
			};
			this.connection.createActSver(
				'1',
				this.$store.state.current_org_id,
				inn,
				moment(this.period_start).startOf('day').unix(),
				moment(this.period_end).endOf('day').unix(),
				additional_data
			)
		},
		onMakePdf() {
			return true;
		},
		checkAgentBy(inn_val) {
			const inn = inn_val.replace(/\s/g, "");
			if (inn.length < 9) {
				return;
			}
			this.connection.getAgentInfoByInn(
				'org_inn',
				inn
			);
		},
	}
}
</script>

<style scoped></style>