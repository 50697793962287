<template>
	<q-page>
		<h5 class="">История платежей</h5>

		<q-table flat borderless :rows="invoices" :columns="columns" row-key="id" :pagination="initialPagination"
			:loading="loading">
			<template v-slot:body-cell-status="props">
				<q-td :props="props">
					<q-chip :color="getStatusColor(props.value)" text-color="white">
						{{ getStatusVerbose(props.value) }}
					</q-chip>
				</q-td>
			</template>
			<template v-slot:body-cell-amount="props">
				<q-td :props="props">
					<q-chip color="info" text-color="white">
						{{ props.value }} руб.
					</q-chip>
				</q-td>
			</template>
			<template v-slot:body-cell-actions="props">
				<q-td :props="props">
					<q-btn flat round color="primary" icon="info" @click="showInvoiceInfo(props.row)">
						<q-tooltip>Информация о платеже</q-tooltip>
					</q-btn>
				</q-td>
			</template>
		</q-table>

		<q-dialog v-model="infoDialog" persistent>
			<q-card style="min-width: 350px">
				<q-card-section>
					<div class="text-h6">Информация о платеже</div>
				</q-card-section>

				<q-card-section v-if="selectedInvoice">
					<q-list>
						<q-item>
							<q-item-section>
								<q-item-label overline>Идентификатор</q-item-label>
								<q-item-label>{{ selectedInvoice.id }}</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label overline>Дата</q-item-label>
								<q-item-label>{{ selectedInvoice.date }}</q-item-label>
							</q-item-section>
						</q-item>
						<q-item>
							<q-item-section>
								<q-item-label overline>Сумма</q-item-label>
								<q-item-label>{{ selectedInvoice.amount }}</q-item-label>
							</q-item-section>
						</q-item>
						<!-- <q-item>
							<q-item-section>
								<q-item-label overline>Описание</q-item-label>
								<q-item-label>{{ selectedInvoice.description }}</q-item-label>
							</q-item-section>
						</q-item> -->
						<q-item>
							<q-item-section>
								<q-item-label overline>Статус</q-item-label>
								<q-item-label>
									<q-chip :color="getStatusColor(selectedInvoice.status)" text-color="white">
										{{ getStatusVerbose(selectedInvoice.status) }}
									</q-chip>
								</q-item-label>
							</q-item-section>
						</q-item>
					</q-list>
				</q-card-section>

				<q-card-actions align="right">
					<q-btn flat v-if="selectedInvoice.status == 'paid'" label="УПД" color="info" @click="onUPD(selectedInvoice)" v-close-popup />
					<q-btn flat v-if="selectedInvoice.status == 'pending'" label="Счет на оплату" @click="onBillToPay(selectedInvoice)" color="info" v-close-popup />
					<q-btn flat label="Закрыть" color="primary" v-close-popup />
				</q-card-actions>
			</q-card>
		</q-dialog>
	</q-page>
</template>

<script>
import { ref } from 'vue'

const columns = [
	{ name: 'id', label: 'Платеж #', field: 'id', sortable: true },
	{ name: 'date', label: 'Дата', field: 'date', sortable: true },
	{ name: 'amount', label: 'Сумма', field: 'amount', sortable: true },
	{ name: 'status', label: 'Статус', field: 'status', sortable: true },
	// { name: 'description', label: 'Описание', field: 'description'},
	{ name: 'actions', label: 'Действия', field: 'actions' }
]


// Sample data - replace this with actual data fetching in a real application
// const invoices = ref([
// 	// { id: 'INV-001', date: '2024-11-01', sum: '1000.00 руб.', status: 'Оплачено' },
// 	// { id: 'INV-002', date: '2024-11-05', sum: '750.00 руб.', status: 'В ожидании' },
// 	// { id: 'INV-003', date: '2024-11-10', sum: '2000.00 руб.', status: 'Не оплачено' },
// 	// { id: 'INV-004', date: '2024-11-15', sum: '500.00 руб.', status: 'Оплачено' },
// 	// { id: 'INV-005', date: '2024-11-20', sum: '1500.00 руб.', status: 'Оплачено' },
// ])



// In a real application, you would fetch invoices from an API
// const fetchInvoices = async () => {
// 	loading.value = true
// 	try {
// 		// Simulating API call
// 		await new Promise(resolve => setTimeout(resolve, 1000))
// 		// In a real scenario, you would set invoices.value to the fetched data
// 	} catch (error) {
// 		console.error('Error fetching invoices:', error)
// 	} finally {
// 		loading.value = false
// 	}
// }

// Call fetchInvoices when the component is mounted
export default {
	name: "my_profile",
	data() {
		this.emitter.on('onGetPaymentsList', (response) => {
			const data = response.data;
			this.invoices = data;
			this.loading = false;
		})
		if (this.connection.isAuthenticated) {
			this.connection.getPaymentsList('1', this.$store.state.current_org_id);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getPaymentsList('1', this.$store.state.current_org_id);
			});
		}
		return {
			loading: ref(true),
			infoDialog: ref(false),
			selectedInvoice: ref(null),
			initialPagination: {
				sortBy: 'date',
				descending: true,
				page: 1,
				rowsPerPage: 10
			},
			columns: ref(columns),
			invoices: ref([])
		}
	},
	methods: {
		getStatusColor(status) {
			switch (status.toLowerCase()) {
				case 'send': return 'info'
				case 'paid': return 'positive'
				case 'pending': return 'warning'
				case 'fail': return 'negative'
				default: return 'grey'
			}
		},
		getStatusVerbose(status) {
			switch (status.toLowerCase()) {
				case 'send': return 'Списано'
				case 'paid': return 'Оплачено'
				case 'pending': return 'В ожидании'
				case 'fail': return 'Не оплачено'
                default: return 'Неизвестен'
            }
		},
		showInvoiceInfo (invoice) {
			this.selectedInvoice = invoice
			this.infoDialog = true
		},
		onBillToPay(invoice) {
			const document_id = invoice.metadata.document_id;
			if (document_id) {
				this.$router.push({ name: 'document_info_bill_to_pay', params: { id: document_id, history_id: 'last' } })
			}
		},
		onUPD(invoice) {
			const upd_id = invoice.metadata.upd_id;
			if (upd_id) {
				this.$router.push({ name: 'document_info_upd', params: { id: upd_id, history_id: 'last' } })
			}
		}
	}
}
</script>

<style scoped>
.q-table {
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
</style>