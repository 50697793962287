<template>
	<q-page-container>
		<q-page class="window-height window-width row justify-center items-center gradient">
			<div class="row" style="width: 768px; height: 480px;">
				<div class="col" style="height: 100%">
					<div style="margin: auto; text-align: center;">
						<q-img src="./../../assets/logo_transp.png" width="175px" height="175px" />
						<h1 style="font-size: 48px; color: gray; font-weight: bold; margin-bottom: 20px;">SID ERP</h1>
					</div>
				</div>
				<div class="col" style="height: 100%;">
					<!-- <transition-group appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut"> -->
					<div key="signin" v-if="login && register" class="card-container"
						style="margin: 0px auto; text-align: center;">
						<div class="text-h5">
							Войдите в систему
						</div>
						<q-input class="q-ma-lg" v-model="email" label="Адрес электронной почты">
							<template v-slot:prepend>
								<q-icon name="person" />
							</template>
						</q-input>
						<q-input class="q-ma-lg" v-model="password" label="Введите пароль"
							:type="showPassword ? 'text' : 'password'">
							<template v-slot:prepend>
								<q-icon name="shield" />
							</template>

							<template v-slot:append>
								<q-icon :name="showPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
									@click="showPassword = !showPassword" />
							</template>
						</q-input>
						<div>
							<q-btn flat @click="signinProcess" label="Авторизоваться" />
						</div>
						<div>
							<q-btn size="sm" flat @click="signup" label="Создать учетную запись" />
						</div>
						<q-inner-loading :showing="isInSignInProcess" label="Пожалуйста подождите"
							label-class="text-teal" label-style="font-size: 1.1em" />
					</div>
					<div key="signup" v-else-if="!login && register" class="card-container"
						style="margin: 0px auto; text-align: center;">
						<div class="text-h5">
							Присоединяйтесь к SID ERP
						</div>
						<q-input class="q-ma-lg" v-model="email" label="Адрес электронной почты"
							@update:model-value="validateRegistration">
							<template v-slot:prepend>
								<q-icon name="person" />
							</template>
						</q-input>
						<q-input class="q-ma-lg" v-model="password" label="Введите пароль"
							:type="showPassword ? 'text' : 'password'" @update:model-value="validateRegistration">
							<template v-slot:prepend>
								<q-icon name="shield" />
							</template>

							<template v-slot:append>
								<q-icon :name="showPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
									@click="showPassword = !showPassword" />
							</template>
						</q-input>
						<!-- <q-input class="q-ma-lg" v-model="inn" label="ИНН организации" mask="## ## ###### ##" borderless
						hint="ИНН / КПП" @update:model-value="checkOrg(inn)">
						<template v-slot:prepend>
							<q-icon name="information" />
						</template>
					</q-input> -->
						<div>
							<q-btn flat @click="signupProcess" label="Создать учетную запись" />
						</div>
						<div>
							<q-btn size="sm" flat @click="signin" label="Авторизоваться" />
						</div>
						<q-inner-loading :showing="isInRegisterProcess" label="Пожалуйста подождите"
							label-class="text-teal" label-style="font-size: 1.1em" />
					</div>
					<div key="confirm" v-else-if="!isConfirmed && !register" class="card-container"
						style="margin: 0px auto; text-align: center;">
						<div class="text-h5">
							Подтвердите аккаунт
						</div>
						<q-input class="q-ma-lg" v-model="code" label="Введите код">
							<template v-slot:prepend>
								<q-icon name="lock" />
							</template>
						</q-input>

						<div>
							<q-btn flat @click="confirmCode" label="Отправить код" />
						</div>
						<div>
							<q-btn size="sm" flat @click="resendCode" label="Отправить заново" />
						</div>
						<q-inner-loading :showing="isInConfirmProcess" label="Пожалуйста подождите"
							label-class="text-teal" label-style="font-size: 1.1em" />
					</div>

					<!-- </transition-group> -->
				</div>
			</div>
		</q-page>
	</q-page-container>

</template>

<script>
import { ref } from 'vue';
import { apiUrl } from "./../../config";
import axios from 'axios'

export default {
	name: "signin",
	data() {
		return {
			email: ref(null),
			password: ref(null),
			showPassword: ref(false),
			login: ref(true),
			register: ref(true),
			isConfirmed: ref(false),
			inn: ref(null),
            code: ref(null),
			isInRegisterProcess: ref(false),
			isInConfirmProcess: ref(false),
			isInSignInProcess: ref(false),
			innValid: ref(false),
			canRegister: ref(false)
		}
	},
	methods: {
		signin() {
			this.login = true;
		},
		signup() {
			this.login = false;
		},
		confirmCode() {
			axios
				.post(apiUrl + '/confirmation/', {
					code: this.code
				}, {
					auth: {
						username: this.email,
						password: this.password
					}
				})
				.then((response) => {
					console.log(response);
					if (response.data.error) {
						this.code = null;
						this.$q.notify({
							progress: true,
							message: "Неверный код подтверждения",
							icon: 'error',
							color: 'white',
							textColor: 'negative'
						});
					} else {
						this.isConfirmed = true;
                        this.register = false;
                        this.emitter.emit('shouldAuth');
					}
				})
				.catch((error) => {
					console.log(error)
					this.isRequestSend = false
				})
		},
		signinProcess() {
			this.requestToken();
		},
		requestToken() {
			this.isInSignInProcess = true;
			axios
				.post(apiUrl + '/user/token/request/', {}, {
					auth: {
						username: this.email,
						password: this.password
					}
				})
				.then((response) => {
					console.log(response);
					if (response.data.token !== undefined) {
						// const instance = getCurrentInstance();
						console.log('token 1', response.data.token, this.login)
						this.$store.commit('setAuthenticate', {
							token: response.data.token,
							token_uuid: response.data.token_uuid
						});
						this.emitter.emit('updateAuth', {
							token: response.data.token,
                            token_uuid: response.data.token_uuid
						});
						if (response.data.user_info) {
							this.$store.commit('updateUserInfo', response.data.user_info);
							this.emitter.emit('receiveUserInfo', response.data.user_info);
						}
						this.isConfirmed = true;//response.data.user_info.is_confirmed;
						this.register = false;
						if (this.login) {
							this.isInSignInProcess = false;
						} else {
							this.isInRegisterProcess = false;
							this.$router.push({ name: 'profile', params: {} });
						}
					} else {
						this.isInRegisterProcess = false;
						this.isInSignInProcess = false;
						var registerError = "Неизвестная ошибка"; 
						const error = response.data.error;
						console.log(error);
						this.email = null;
						this.password = null;
						this.$q.notify({
							progress: true,
							message: registerError,
							icon: 'error',
							color: 'white',
							textColor: 'negative'
						});
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		validateRegistration() {
			if (this.email !== null && this.password !== null) { 
				var emailRegex = /\S+@\S+\.\S+/;
				if (!emailRegex.test(this.email)) {
					this.canRegister = false;
					return false;
				}
				if (this.password.length < 3) {
					this.canRegister = false;
					return false;
				}
				this.canRegister = true;
				return true;
			}
			this.canRegister = false;
			return false;
		},
		checkOrg(inn_val) {
			const inn = inn_val.replace(/\s/g, "");
			if (inn.length < 9) {
				return;
			}
			axios
				.post(apiUrl + '/check/org/', {
					inn: inn
				})
				.then((response) => {
					console.log(response);
					if (response.data.error) {
						this.innValid = false;
                        this.$q.notify({
                            progress: true,
                            message: "Неправильный ИНН организации",
                            icon: 'error',
                            color: 'white',
                            textColor: 'negative'
                        });
					} else {
						this.innValid = true;
                        this.$q.notify({
                            progress: true,
                            message: "ИНН организации проверен",
                            icon:'success',
                            color: 'white',
                            textColor: 'positive'
                        });
					}
					this.validateRegistration();
				})
				.catch((error) => {
					console.log(error)
					this.isRequestSend = false
				})
		},
		signupProcess() {
			if (!this.canRegister) {
				this.$q.notify({
					progress: true,
					message: "Не заполнены данные для регистрации",
					icon: 'error',
					color: 'white',
					textColor: 'negative'
				});
				return;
			}
			this.isInRegisterProcess = true;
			axios
				.post(apiUrl + '/register/', {
					email: this.email,
					password: this.password
				})
				.then((response) => {
					if (response.data.success === 1) {
						this.requestToken();
					} else {
						const error = response.data.error;
						var registerError = "Неизвестная ошибка";
						console.log(error);
						if (error == 'user-exist') {
							registerError = 'Такой email уже используется';
						} else if (error == 'invalid-email') {
							registerError = 'Такой email не существует';
						}
						this.email = null;
						this.password = null;
						this.$q.notify({
							progress: true,
							message: registerError,
							icon: 'error',
							color: 'white',
							textColor: 'negative'
						});
					}
				})
				.catch((error) => {
					console.log(error)
					this.isRequestSend = false
				})
		}
	}
}
</script>

<style scoped>
	.gradient {
		background: #E0EAFC;
		background: -webkit-linear-gradient(to right, #CFDEF3, #E0EAFC);
		background: linear-gradient(to right, #CFDEF3, #E0EAFC);
	}
	.card-container {
		width: 100%;
		max-width: 400px;
		padding: 0px 40px;
		margin: auto 0;
		float: right;
		height: 100%;
		max-height: 380px;
	}
</style>