<template>
	<div class="container">
		<h6 class="header">Отчет по НДС за <q-input class="inline" dense flat v-model="document_date">
				<template v-slot:append>
					<q-icon name="event" class="cursor-pointer">
						<q-popup-proxy cover transition-show="scale" transition-hide="scale">
							<q-date mask="DD.MM.YYYY" v-model="document_date"
								@update:model-value="(newValue) => onDateChanged(newValue)">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Закрыть" color="primary" flat />
								</div>
							</q-date>
						</q-popup-proxy>
					</q-icon>
				</template>
			</q-input> <q-btn style="margin-left: 32px;" @click="onFillDocument" :disable="document_date == null"
				label="Заполнить на дату" flat dense /></h6>
		<div class="row" style="width: 180mm;">
			
		</div>
		
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];

export default {
	name: "create_document_report_nds",
	data() {
		this.emitter.on('didCreateNewDocument', (data) => {
			console.log(data);
			this.$q.loading.hide();
			if (data.error) {
				this.$q.dialog({
					title: 'Ошибка',
					message: 'Документ не был создан',
					cancel: false,
					persistent: true
				}).onOk(() => {
				}).onCancel(() => {
					console.log('cancel')
				}).onDismiss(() => {
					console.log('dismiss')
				})
			} else {
				this.$q.notify('Документ был создан');
				this.$router.push({ name: 'reports_list', params: {} });
			}
		})
		this.emitter.on('onGenerateReportBalance', (response) => {
			const body = response.body;
			this.organisation_name = body.organisation_name;
			this.organisation_inn = body.organisation_inn;
			this.organisation_econ = body.organisation_econ;
			this.organisation_form = body.organisation_form;
			this.organisation_address = body.organisation_address;
			this.organisation_audit_id = body.organisation_audit_id;
			this.organisation_audit_name = body.organisation_audit_name;
			this.organisation_audit_num = body.organisation_audit_num;
			this.audit = body.audit;
			this.document_date_unix = body.document_date_unix;
			this.document_date = body.document_date;
			this.currency_measure = body.currency_measure;
			this.nematerial_i = body.nematerial_i;
			this.dev_result_i = body.dev_result_i;
			this.nonmat_assets_i = body.nonmat_assets_i;
			this.mat_act_i = body.mat_act_i;
			this.fix_assets_i = body.fix_assets_i;
			this.mat_assets_i = body.mat_assets_i;
			this.fin_income_i = body.fin_income_i;
			this.tax_active_i = body.tax_active_i;
			this.other_assets_i = body.other_assets_i;
			this.total_i = body.total_i;
			this.stocks_ii = body.stocks_ii;
			this.tax_nds_ii = body.tax_nds_ii;
			this.deb_dolg_ii = body.deb_dolg_ii;
			this.fin_income_ii = body.fin_income_ii;
			this.money_ii = body.money_ii;
			this.other_assets_ii = body.other_assets_ii;
			this.total_ii = body.total_ii;
			this.active_balance = body.active_balance;
			this.auth_cap_iii = body.auth_cap_iii;
			this.self_prom_iii = body.self_prom_iii;
			this.reval_assets_iii = body.reval_assets_iii;
			this.add_cap_iii = body.add_cap_iii;
			this.rezerv_kap_iii = body.rezerv_kap_iii;
			this.ret_earn_iii = body.ret_earn_iii;
			this.total_iii = body.total_iii;
			this.cred_money_iv = body.cred_money_iv;
			this.delayed_tax_iv = body.delayed_tax_iv;
			this.est_liab_iv = body.est_liab_iv;
			this.other_oblig_iv = body.other_oblig_iv;
			this.total_iv = body.total_iv;
			this.dolg_v = body.dolg_v;
			this.cred_bill_v = body.cred_bill_v;
			this.fut_income_v = body.fut_income_v;
			this.est_liab_v = body.est_liab_v;
			this.other_oblig_v = body.other_oblig_v;
			this.total_v = body.total_v;
			this.passive_balance = body.passive_balance;
			this.in_org_number = body.in_org_number;
			this.document_date = moment.unix(body.document_date_unix).format('DD.MM.YYYY');
			this.document_date_unix = body.document_date_unix;
			this.$q.loading.hide();
		});
		this.emitter.on('didReceiveNewDocumentNumber', (data) => {
			this.in_org_number = data.number;
			this.step = 2;
		});
		this.emitter.on('didReceiveAgentInfoByInn', (data) => {
			console.log(data, 'agent')
			if (data.request_id === 'rec_inn') {
				this.rec = data.info.name.short_with_opf;
				this.rec_kpp = data.info.kpp;
			}
			if (data.request_id === 'plat_inn') {
				this.plat = data.info.name.short_with_opf;
				this.plat_kpp = data.info.kpp;
			}
		})
		if (this.connection.isAuthenticated) {
			this.connection.getNewDocumentNumber(
				'1',
				this.$store.state.current_org_id,
				'report'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getNewDocumentNumber(
					'1',
					this.$store.state.current_org_id,
					'report'
				);
			})
		}
		this.emitter.on('onGetNDSReport', (response) => {

			fetch(response.url)
				.then((res) => {
					if (!res.ok) {
						throw new Error("Network Problem");
					}
					return res.blob();
				})
				.then((file) => {
					var anchor = document.createElement("a")
					anchor.href = URL.createObjectURL(file);
					anchor.download = response.name;
					anchor.setAttribute('target', '_blank')
					anchor.click()
					URL.revokeObjectURL(anchor.href)
					anchor.remove()
				})
				.catch(() => {

				});
		});
		return {
			step: ref(1),
			document_type: ref('report'),
			in_org_number: ref(null),
			organisation_name: ref(null),
			organisation_inn: ref(null),
			organisation_econ: ref(null),
			organisation_form: ref(null),
			organisation_address: ref(null),
			organisation_audit_id: ref(null),
			organisation_audit_name: ref(null),
			organisation_audit_num: ref(null),
			audit: ref(false),
			document_date_unix: ref(0),
			document_date: ref(null),
			currency_measure_options: ref(currency_measure_options),
			currency_measure: ref(currency_measure_options[0]),
			nematerial_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			dev_result_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			nonmat_assets_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			mat_act_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			fix_assets_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			mat_assets_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			fin_income_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			tax_active_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			other_assets_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			total_i: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			stocks_ii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			tax_nds_ii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			deb_dolg_ii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			fin_income_ii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			money_ii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			other_assets_ii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			total_ii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			active_balance: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			auth_cap_iii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			self_prom_iii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			reval_assets_iii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			add_cap_iii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			rezerv_kap_iii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			ret_earn_iii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			total_iii: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			cred_money_iv: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			delayed_tax_iv: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			est_liab_iv: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			other_oblig_iv: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			total_iv: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			dolg_v: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			cred_bill_v: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			fut_income_v: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			est_liab_v: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			other_oblig_v: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			total_v: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
			passive_balance: ref({ current: 0, previous: 0, preprevious: 0, desc: '' }),
		}
	},
	methods: {
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		checkAgentBy(bik_val, field_id) {
			const bik = bik_val.replace(/\s/g, "");
			console.log(bik, field_id)
			if (bik.length < 9) {
				return;
			}
			this.connection.getAgentInfoByBik(
				field_id,
				bik
			);
		},
		checkAgentByInn(inn_val, field_id) {
			const inn = inn_val.replace(/\s/g, "");
			console.log(inn, field_id)
			if (inn.length < 9) {
				return;
			}
			this.connection.getAgentInfoByInn(
				field_id,
				inn
			);
		},
		onSave() {
			const body = {
				organisation_name: this.organisation_name,
				organisation_inn: this.organisation_inn,
				organisation_econ: this.organisation_econ,
				organisation_form: this.organisation_form,
				organisation_address: this.organisation_address,
				organisation_audit_id: this.organisation_audit_id,
				organisation_audit_name: this.organisation_audit_name,
				organisation_audit_num: this.organisation_audit_num,
				audit: this.audit,
				currency_measure: this.currency_measure,
				nematerial_i: this.nematerial_i,
				dev_result_i: this.dev_result_i,
				nonmat_assets_i: this.nonmat_assets_i,
				mat_act_i: this.mat_act_i,
				fix_assets_i: this.fix_assets_i,
				mat_assets_i: this.mat_assets_i,
				fin_income_i: this.fin_income_i,
				tax_active_i: this.tax_active_i,
				other_assets_i: this.other_assets_i,
				total_i: this.total_i,
				stocks_ii: this.stocks_ii,
				tax_nds_ii: this.tax_nds_ii,
				deb_dolg_ii: this.deb_dolg_ii,
				fin_income_ii: this.fin_income_ii,
				money_ii: this.money_ii,
				other_assets_ii: this.other_assets_ii,
				total_ii: this.total_ii,
				active_balance: this.active_balance,
				auth_cap_iii: this.auth_cap_iii,
				self_prom_iii: this.self_prom_iii,
				reval_assets_iii: this.reval_assets_iii,
				add_cap_iii: this.add_cap_iii,
				rezerv_kap_iii: this.rezerv_kap_iii,
				ret_earn_iii: this.ret_earn_iii,
				total_iii: this.total_iii,
				cred_money_iv: this.cred_money_iv,
				delayed_tax_iv: this.delayed_tax_iv,
				est_liab_iv: this.est_liab_iv,
				other_oblig_iv: this.other_oblig_iv,
				total_iv: this.total_iv,
				dolg_v: this.dolg_v,
				cred_bill_v: this.cred_bill_v,
				fut_income_v: this.fut_income_v,
				est_liab_v: this.est_liab_v,
				other_oblig_v: this.other_oblig_v,
				total_v: this.total_v,
				passive_balance: this.passive_balance,
				document_date: this.document_date_unix,
				in_org_number: this.in_org_number,
				document_type: 'report',
				report_type: 'balance'
			};
			this.$q.loading.show();
			this.connection.createNewDocument(
				'3',
				this.$store.state.current_org_id,
				body
			);

		},
		onFillDocument() {
			this.connection.getNDSReport(
				'1',
				this.$store.state.current_org_id,
			)
			
		}

	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	/* width: 210mm;
	height: 297mm; */
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
}
</style>
