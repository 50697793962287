<template>
	<div class="row">
		<div class="q-pa-md">
			<div class="q-pa-md">
				<div class="row">
					<div class="col-6 q-pa-md">
						<div class="text-h6">
							Тип отчета
						</div>
						<div>
							<q-select flat v-model="selected_report" :options="reports_options" />
						</div>
					</div>
					<div class="col-6 q-pa-md">
						<div class="text-h6">
							Дата отчета
						</div>
						<div class="inline-block">
							<q-input class="inline" dense flat v-model="document_date">
								<template v-slot:append>
									<q-icon name="event" class="cursor-pointer">
										<q-popup-proxy cover transition-show="scale" transition-hide="scale">
											<q-date mask="DD.MM.YYYY" v-model="document_date"
												@update:model-value="(newValue) => onDateChanged(newValue)">
												<div class="row items-center justify-end">
													<q-btn v-close-popup label="Закрыть" color="primary" flat />
												</div>
											</q-date>
										</q-popup-proxy>
									</q-icon>
								</template>
							</q-input>
						</div>
					</div>


				</div>
				<div>
					<div class="inline-block q-pa-sm">
						<q-btn @click="onRequestReport" unelevated color="primary" icon-right="code" text-color="white"
							label="Сформировать" />
					</div>
					<!-- <div class="inline-block q-pa-sm">
						<q-btn @click="onMakePdf" unelevated color="white" icon-right="download" text-color="primary"
							label="Печать" />
					</div> -->
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const reports_options = [
	{ label: 'Бухгалтерский баланс', value: 'balance' },
	{ label: 'Отчет о финансовых резульататх', value: 'fin_rez' },
	{ label: 'Отчет об изменениях капитала', value: 'cap_change' },
	{ label: 'Отчет о движении денежных средств', value: 'money_move' },
	{ label: 'Отчет о целевом использовании средств', value: 'money_using' }
];


export default {
	name: "bill_saldo_master",
	components: {
	},
	data() {
		this.emitter.on('onGenerateReportBalance', (data) => {
			console.log(data);
			const document_id = data.id;
			this.$q.loading.hide();
			this.$router.push({ name: 'reports_info_balance', params: { id: document_id, history_id: 'last' } })
		});
		this.emitter.on('onGenerateReportFinRez', (data) => {
			console.log(data);
            const document_id = data.id;
            this.$q.loading.hide();
            this.$router.push({ name:'reports_info_fin_rez', params: { id: document_id, history_id: 'last' } })
		});
		this.emitter.on('onGenerateReportMoneyUsing', (data) => {
			console.log(data);
			const document_id = data.id;
			this.$q.loading.hide();
			this.$router.push({ name: 'reports_info_money_using', params: { id: document_id, history_id: 'last' } })
		});
		this.emitter.on('onGenerateReportMoneyMove', (data) => {
			console.log(data);
			const document_id = data.id;
			this.$q.loading.hide();
			this.$router.push({ name: 'reports_info_money_move', params: { id: document_id, history_id: 'last' } })
		}); 
		this.emitter.on('onGenerateReportCapChange', (data) => {
			console.log(data);
			const document_id = data.id;
			this.$q.loading.hide();
			this.$router.push({ name: 'reports_info_cap_change', params: { id: document_id, history_id: 'last' } })
		});
		return {
			step: ref(1),
			isUpdatingData: ref(false),
			document_date_unix: ref(0),
			document_date: ref(null),
			selected_report: ref(reports_options[0]),
			reports_options: ref(reports_options)
		}
	},
	methods: {
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		onRequestReport() {
			if (this.selected_report.value == 'balance') {
				this.$q.loading.show();
				this.connection.generateReportBalance(
					'1',
					this.$store.state.current_org_id,
					this.document_date_unix
				)
			} else if (this.selected_report.value == 'fin_rez') {
				this.$q.loading.show();
                this.connection.generateReportFinRez(
                    '1',
                    this.$store.state.current_org_id,
                    this.document_date_unix
                )
			} else if (this.selected_report.value == 'money_using') {
				this.$q.loading.show();
				this.connection.generateReportMoneyUsing(
					'1',
					this.$store.state.current_org_id,
					this.document_date_unix
				)
			} else if (this.selected_report.value == 'money_move') {
				this.$q.loading.show();
				this.connection.generateReportMoneyMove(
					'1',
					this.$store.state.current_org_id,
					this.document_date_unix
				)
			} else if (this.selected_report.value == 'cap_change') {
				this.$q.loading.show();
				this.connection.generateReportCapChange(
					'1',
					this.$store.state.current_org_id,
					this.document_date_unix
				)
			} else {
				this.$q.notify({ type: 'negative', message: 'Внутренняя ошибка сервера' });
			}
		}
	}
}
</script>

<style scoped></style>