<template>

  <div class="row">
    <div class="col">
      <q-card flat class="erp-tariff-card">
        <q-card-section>
          <div class="text-h5">Тарифный план</div>
          <!-- <div class="text-subtitle2">Select modules and adjust workplaces to calculate your daily billing</div> -->
        </q-card-section>

        <q-card-section class="q-gutter-y-md">
          <q-banner class="bg-primary text-white">
            <template v-slot:avatar>
              <q-icon name="account_balance" color="white" />
            </template>
            <div class="text-h6">Баланс: {{ accountBalance.toFixed(2) }} руб.</div>
            <div>Хватит на {{ remainingDays.toFixed(0) }} дней</div>
          </q-banner>

          <div v-for="module in modules" :key="module.id" class="q-mb-md">
            <q-item tag="label" v-ripple>
              <q-item-section avatar>
                <q-checkbox :disable="canChangeModule(module)" v-model="module.is_active"
                  @update:model-value="() => { calculateDailyBilling() }" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ module.template.name }}</q-item-label>
                <q-item-label caption>
                  Рабочих мест: {{ module.template.workplaces + module.workplaces }}
                </q-item-label>
              </q-item-section>
              <q-item-section side v-show="edit_mode && module.template.is_required">
                <div class="row items-center">
                  <q-btn icon="remove" color="grey" round dense flat
                    :disable="!module.is_active || module.workplaces === 0"
                    @click="() => { changeAdditionalWorkplaces(module.id, -1) }" />
                  <div class="q-mx-sm">
                    {{ module.workplaces }}
                  </div>
                  <q-btn icon="add" color="grey" round dense flat :disable="!module.is_active"
                    @click="() => {changeAdditionalWorkplaces(module.id, 1);}" />
                </div>
              </q-item-section>
            </q-item>
          </div>
        </q-card-section>


        <q-card-section class="row items-center justify-between">
          <div class="text-h6">Рабочих мест</div>
          <div class="text-h6">{{ roles_count }} / {{ workplaces }}</div>
        </q-card-section>
        <q-card-section class="row items-center justify-between">
          <div class="text-h6">Списывается в день:</div>
          <div class="text-h6">{{ pricePerDay.toFixed(2) }}руб.</div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat icon="history" label="Пополнить баланс" @click="createPaymentDialog = true" />
          <q-btn v-if="!edit_mode" flat icon="history" label="История платежей" @click="invoicesHistory" />
          <q-btn v-if="!edit_mode" flat icon="edit" label="Редактировать" @click="edit_mode = true" />
          <q-btn v-else color="primary" icon="save" :loading="save_billing_details" label="Сохранить"
            @click="saveBillingDetails" />
        </q-card-actions>

        <q-dialog v-model="showWarning" persistent>
          <q-card>
            <q-card-section class="row items-center">
              <q-avatar icon="warning" color="warning" text-color="white" />
              <span class="q-ml-sm">Your account will be suspended in {{ remainingDays }} days. Please add funds to
                continue service.</span>
            </q-card-section>
            <q-card-actions align="right">
              <q-btn flat label="Dismiss" color="primary" v-close-popup />
              <q-btn flat label="Add Funds" color="primary" v-close-popup />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </q-card>
    </div>
    <div class="col q-pa-md">
      <div class="text-h5">
        Структура
      </div>
      <div v-if="is_all_data_loaded" class="widget-department">
        <blocks-tree @node-click="onNodeClick" :key="department_structure_tree" :data="department_structure"
          :horizontal="widget_settings.horizontal || false" :collapsable="true"
          :props="{ label: 'label', expand: 'expand', children: 'children', key: 'key' }">
          <template #node="{ data }">
            <div>{{ (data.unit_info || { role: '' }).role || " " }}</div>
            <!--          <div>{{ data.unit_info.user || data.unit_info.email }}</div>-->
            <div v-if="data.unit_info.invite.empty">
              <div v-if="data.unit_info.email == undefined">
                <span class="text-green-9">Место вакантно</span>
              </div>
              <div v-else>
                <span v-if="data.unit_info.is_me" class="text-green-9">
                  {{ data.unit_info.user || data.unit_info.email }}
                </span>
                <span v-else>
                  {{ data.unit_info.user || data.unit_info.email }}
                </span>
              </div>
            </div>
            <div v-else>
              <div v-if="data.unit_info.invite.is_accepted">
                <span v-if="data.unit_info.is_me" class="text-green">
                  {{ data.unit_info.user || data.unit_info.email }}
                </span>
                <span v-else>
                  {{ data.unit_info.user || data.unit_info.email }}
                </span>
              </div>
              <div v-else class="text-yellow-9">
                {{ data.unit_info.invite.email }}
              </div>
            </div>
          </template>
        </blocks-tree>
      </div>
    </div>

  </div>
  <q-page-sticky position="bottom-right" :offset="[18, 18]">
    <q-fab icon="add" direction="left" color="primary">
      <q-fab-action @click="onCreateNewRole" color="primary" icon="add_to_queue" label="Рабочее место" />
    </q-fab>
  </q-page-sticky>
  <q-dialog v-model="createPaymentDialog" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">Пополнить баланс</div>
      </q-card-section>

      <q-card-section>
        <q-list>
          <q-item>
            <q-item-section>
              <q-item-label overline>Сумма</q-item-label>
              <q-item-label><q-input v-model="newPaymentAmount" label="Сумма" /></q-item-label>
            </q-item-section>
          </q-item>
          <!-- <q-item>
            <q-item-section>
              <q-item-label overline>Описание</q-item-label>
              <q-item-label><q-input v-model="newPaymentDescription" label="Описание" /></q-item-label>
            </q-item-section>
          </q-item> -->
        </q-list>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Получить счет" color="primary" :loading="newPaymentLoading" @click="createPayment" />
        <q-btn flat label="Закрыть" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, reactive } from 'vue'


export default {
  name: "org_main",
  data() {
    this.$store.commit("getOrCreateDepartmentWidgetSettings");
    var widget_settings = {};
    try {
      widget_settings = this.$store.state.department_structure_widget;
    } catch {
      widget_settings = {
        is_hidden: false,
        horizontal: false
      }
    }
    if (this.connection.isAuthenticated) {
      this.connection.getOrgStructure('1', this.$store.state.current_org_id);
      this.connection.getBillingDetails('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getOrgStructure('1', this.$store.state.current_org_id);
        this.connection.getBillingDetails('1', this.$store.state.current_org_id);
      });
    }
    this.emitter.on('onSaveBillingDetails', (data) => {
      console.log(data);
      this.save_billing_details = false;
      this.edit_mode = false;
      this.$q.notify('Настройки тарифного плана успешно сохранены');
      this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
    })
    this.emitter.on('onBillingDetails', (response) => {
      const data = response.data;
      this.accountBalance = data.balance;
      this.pricePerDay = data.daily_amount;
      this.remainingDays = this.accountBalance / this.pricePerDay;
      this.modules = data.modules;
      this.calculateDailyBilling()
    });
    this.emitter.on('didReceiveOrgStructure', (data) => {
      this.$store.commit('updateDepartmentStructure', data.roles);
      this.department_structure = data.roles;
      this.is_all_data_loaded = true;
      this.roles_count = data.roles_count;
    });
    this.emitter.on('onCreatePayment', (response) => {
      this.$q.notify('Платеж создан успешно');
      console.log(response);
      this.newPaymentLoading = false;
      this.newPaymentAmount = 500;
      this.newPaymentDescription = '';
      this.createPaymentDialog = false;
      this.$router.push({ name: 'document_info_bill_to_pay', params: {id: response.data.id, history_id: 'last'}})
    })
    return {
      widget_settings: ref(widget_settings),
      department_structure_tree: ref(0),
      is_all_data_loaded: ref(this.$store.state.department_structure.length > 0),
      department_structure: reactive(this.$store.state.department_structure),
      roles_count: ref(0),
      edit_mode: ref(false),
      modules: ref([]),
      workplaces: ref(3),
      accountBalance: ref(1000),
      remainingDays: ref(0) ,
      showWarning: ref(false),
      pricePerDay: ref(0),
      save_billing_details: ref(false),
      createPaymentDialog: ref(false),
      newPaymentAmount: ref(500),
      newPaymentDescription: ref(''),
      newPaymentLoading: ref(false)
    }
  },
  methods: {
    onHorizontalSwitchSettingsToggle() {
      this.department_structure_tree += 1;
      this.$store.commit("updateDepartmentWidgetSettings", this.widget_settings);
    },
    onCreateNewRole() {
      if (this.workplaces > this.roles_count) {
        this.$router.push({ name: 'org_role_new', params: {} })
      } else {
        this.$q.notify('Вы не можете создавать больше рабочих мест.');
      }
    },
    onNodeClick(data, context) {
      console.log(data, context)
      this.$router.push({ name: 'org_role_settings', params: { id: context.key } })
    },
    onInvoiceTouchUp() {
      window.open("./../assets/invoice.pdf", "_blank")
    },
    newBillToPay() {
      this.connection.createNewTarifBillToPay({
        summ: this.bill_to_pay_summ
      })
    },
    changeAdditionalWorkplaces (id, change) {
      const module = this.modules.find(m => m.id === id)
      const total_base_worplaces = this.modules
        .filter(m => m.is_active)
        .reduce((total, m) => total + m.template.workplaces, 0)
      if (module) {
        module.workplaces = Math.max(this.roles_count - total_base_worplaces, module.workplaces + change)
      }
      this.calculateDailyBilling()
    },
    calculateDailyBilling() {
      this.pricePerDay = 0;
      this.pricePerDay = this.modules
        .filter(m => m.is_active)
        .reduce((total, m) => total + m.template.price + m.template.price_per_workplace * (m.workplaces), 0)
      this.workplaces = this.modules
        .filter(m => m.is_active)
        .reduce((total, m) => total + m.template.workplaces + m.workplaces, 0)
    },
    saveBillingDetails() {
      this.save_billing_details = true;
      this.connection.saveBillingDetails(
        '1',
        this.$store.state.current_org_id,
        this.modules
      )
    },
    saveChangesTarif() {
      this.edit_mode = false;
    },
    invoicesHistory() {
      this.$router.push({ name: 'org_invoices_history', params: {} })
    },
    canChangeModule(module) {
      console.log('edit', this.edit_mode)
      if (!this.edit_mode) {
        return true;
      }else {
        if (!module.is_active) {
          return false;
        }
        console.log('mask', (this.workplaces - module.template.workplaces) < this.roles_count)
        return (this.workplaces - module.template.workplaces) < this.roles_count
      }      
    },
    createPayment() {
      this.newPaymentLoading = true;
      this.connection.createPayment(
        '1',
        this.$store.state.current_org_id,
        this.newPaymentAmount,
        this.newPaymentDescription,
        'income'
      )
    }
  }
}
</script>

<style></style>